@import 'src/assets/styles/variables';

.form {
  &__button {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    width: 100%;
    padding: 0.75rem 0;
    transition: min-width 0s;
    text-align: center;
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem;
    text-transform: uppercase;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    outline: none;
    cursor: pointer;
    letter-spacing: 0.125rem;

    &--accept {
      border-color: $color_primary;
      color: $color_white;
      background-color: $color_primary;

      &:active,
      &:hover {
        border-color: $color_primary;
        color: $color_white;
        background: $background_buttons;
        box-shadow: $shadow_buttons;
      }
    }

    &--disabled {
      color: $color_secondary_gray_light;
      background: transparent;
      border-color: $color_secondary_gray_light;
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
