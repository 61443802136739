@import "assets/styles/variables.scss";

.textInput {
  padding: 0.5rem 0;
  display: flex;
  min-height: 2.3rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid $textarea_border;
  font-family: 'Etelka Text', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
  text-align: left;
  background-color: transparent;
  color: $color_secondary_text;
  outline: none;

  &[aria-required="true"] {
    border-color: $color_primary;
  }

  &:not([data-disabled="true"]):hover {
    border-color: $textarea_border_hover;
  }
}

.error {
  margin-top: 0.4rem;
  color: $color_primary;
}

.isLocked {
  pointer-events: none;
}
