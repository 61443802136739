.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-family: "Etelka", Arial, sans-serif;
}

.content {
  width: 100%;
  margin: 14.2rem 0;
  display: flex;
  gap: 3.1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error {
  max-width: 480px;
}
