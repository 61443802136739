@import 'assets/styles/mixins.scss';

$rowHeight: 4rem;

.listItem {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  height: $rowHeight;
}

@keyframes elevator {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.elevator {
  animation: elevator 0.5s ease-in-out;
}
