@import 'src/assets/styles/variables.scss';

.delete-button {
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: $color_secondary_text;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:focus-within,
  &:hover {
    text-decoration: none;
  }

  &__icon {
    margin-left: 0.8rem;
  }

  &--with-body {
    padding: 0.3rem 1.2rem;
    border: 1px solid $color_primary;
    font-weight: 500;
    text-decoration: none;
    color: $color_primary;

    &:hover {
      border: 1px solid $color_primary;
      color: $color_white;
    }
  }

  &--hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &--disabled {
    path {
      fill-opacity: 0.3;
    }
    pointer-events: none;
  }
}
