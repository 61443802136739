@import "assets/styles/variables.scss";

.editor {
  position: relative;
  border: none;
  border-bottom: 1px solid $color_secondary_gray_light;
  border-radius: 0;

  :global {
    .rdw-editor-wrapper {
      height: 100%;
    }

    .rdw-option-wrapper {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: none;

      &:hover {
        background-color: $color_secondary_gray_light;
        border: none;
        box-shadow: none;
      }
    }

    .DraftEditor-root {
      z-index: 0;
    }

    .rdw-option-active {
      background-color: $color_secondary_gray_light;
      box-shadow: none;
    }

    .public-DraftStyleDefault-block {
      margin: 0.6rem 0;
    }

    .public-DraftStyleDefault-orderedListItem {
      list-style-type: inherit;
    }

    .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR::before {
      display: none;
    }

    .DraftEditor-editorContainer {
      margin-right: 3rem;

      & span {
        font-size: 13px !important;
        color: initial !important;
        word-break: break-word;
        background-color: transparent !important;
      }

      & ul,
      & ol {
        list-style: auto;
      }

      & u {
        text-decoration: underline;
      }
    }

    .public-DraftEditor-content {
      & div {
        overflow: hidden;
      }
    }

    .public-DraftEditorPlaceholder-root {
      transform: translate(0px, 5px);
    }
  }

  &.error {
    border-bottom: 1px solid $primary_button_color;
  }
}

.toolbar {
  background-color: $color_secondary_gray_light_0_15 !important;
  border-bottom: 1px solid $color_secondary_gray_light !important;
}

.active {
  :global {
    .rdw-editor-main {
      padding-top: 2rem;
    }
  }
}

.error {
  :global {
    .rsw-editor {
      border-bottom: 1px solid $primary_button_color;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
}

.edit {
  position: absolute;
  right: 5px;
  bottom: 0;

  svg {
    width: 20px;
    height: 20px;
  }
}
