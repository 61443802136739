@import 'assets/styles/variables.scss';
@import 'assets/styles/mixins.scss';

.tableFilters {
  border: 1px solid $filter_border_color;
  border-right: none;
  display: flex;
  gap: 0.1rem;
  width: 100%;
  background-color: $filter_border_color;
  margin-bottom: 0.2rem;
}

.tableFiltersContainer {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.clearFilter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  min-height: 1.6rem;
}

.clearFilterButton {
  @include tertiary-button();
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: $color_primary;
  padding: 0;
  width: max-content;
  &:hover {
    text-decoration: none;
  }
}
