.table {
  & tr {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  & th,
  & td {
    font-size: 1.3rem;
  }

  & th div,
  & tr td {
    padding: 1.6rem;
    line-height: 1.231;
  }
}
