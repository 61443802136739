@import 'assets/styles/variables.scss';


.go-top-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: $color_background_go_top_button;
  border: none;
  outline: none;
  cursor: pointer;
  transform: translate(-100%, -100%) rotate(180deg);
  opacity: 0.8;
  animation-name: fade;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  z-index: 2;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
