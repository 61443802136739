@import "assets/styles/variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.3rem;
}

.logo {
  width: 107px;
  height: 80px;
}

.heading {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 400;
  color: $header_text_color;
}

.content {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.5;
  color: $header_text_color;
}
