@import 'assets/styles/variables.scss';

.order-button {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
  font-family: inherit;
  font-size: 1.2rem;
  line-height: 2.1rem;
  font-weight: 500;
  text-transform: inherit;
  color: inherit;
  background-color: transparent;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: $transition_speed;

  &--active {
    color: $color_primary;

    span svg {
      color: $color_primary;
    }
  }

  &:focus,
  &:hover {
    color: $color_primary;

    span svg {
      color: $color_primary;
    }
  }

  &__icon {
    width: auto;
    height: 100%;
    max-height: 1.6rem;
    margin-left: 0.6rem;
    transition: $transition_speed;

    &--reversed {
      transform: rotate(180deg);
    }

    svg {
      max-width: 1.2rem;
      color: $color_secondary_gray_light;
      transition: inherit;
    }
  }

  &--disabled {
    cursor: default;
  }
}
