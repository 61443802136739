@import "assets/styles/variables.scss";

.details-table {
  position: relative;
  display: flex;
  flex-direction: row;
  // flex-grow: 1;
  grid-area: detailsTable;
  width: 100%;
  height: 100%;
  // min-height: 18rem;
  .table {
    min-height: 17rem;
    padding-bottom: 5rem;

    &--with-loader {
      min-height: 18rem;
    }

    &__content-wrapper {
      padding-bottom: 0;
    }
  }

  &__multi-tables-wrapper {
    width: 100%;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    min-height: 3.4rem;
    margin-top: 1.6rem;
  }

  &__title {
    margin-left: 1.6rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $color_secondary_text;

    &--additional {
      padding-bottom: 1rem;
    }
  }

  &__button {
    display: inline-flex;
    margin-left: 2.4rem;
    padding: 0.8rem 3.2rem;
    text-decoration: none;
    letter-spacing: 1.25px;
    text-align: center;
  }

  .row-wrapper {
    & > .grid-unit:first-child {
      padding-left: 1.6rem;
    }
  }
}
