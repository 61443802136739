.title {
  margin: 2rem 1.6rem;
  font-size: 2.2rem;
}

.table {
  & tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  & th,
  & td {
    font-size: 1.3rem;
  }

  & th div,
  & tr td {
    padding: 1.6rem;
  }
}

.tableSmall {
  & tr {
    grid-template-columns: 0.5fr 0.5fr 1fr;
  }
}

.empty {
  pointer-events: none;
  tbody {
    tr {
      grid-template-columns: 1fr;
    }
    td {
     padding: 5rem 1.6rem;
    }
  }
}
