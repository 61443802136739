@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.title {
  font-size: 1.3rem;
  color: $dashed_button_color;
  margin-bottom: 1rem;
}

.inputContainer {
  position: relative;
}

.input {
  display: block;
  width: 100%;
  padding: 1.23rem;
  font-size: 14px;
  color: $dashed_button_hover_color;
  background: $color_background;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  box-sizing: border-box;
}

.notification {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: $color_primary;
  margin-top: 0.5rem;

  &::before {
    margin-right: 0.5rem;
    content: url('../../assets/icons/check.svg');
  }
}

.copyToClipboardButton {
  @include primary-button();
  @include centred-control();
  position: absolute;
  top: 50%;
  right: 1.2rem;
  padding: 1.266rem 0.774rem;
  line-height: 0.07rem;
  text-transform: uppercase;
  text-decoration: none;
  transform: translate(0, -50%);
  border: none;
  cursor: pointer;
}
