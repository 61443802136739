@import 'src/assets/styles/variables';

.new-text-input {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $color_secondary_text;

  &--textarea {
    box-sizing: border-box;
    padding-bottom: 0.3rem;
  }

  &__input-field {
    height: 3rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    font-size: 1em;
    font-family: $font-main;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: 0.15px;
    text-align: inherit;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color_secondary_gray_light;
    color: inherit;
    outline: none;
    cursor: text;

    &::-webkit-credentials-auto-fill-button {
      position: absolute;
      top: -100vh;
      visibility: hidden;
      pointer-events: none;
    }

    &:hover {
      border-bottom: 1px solid $color_border_gray_light;
    }

    &:focus-within {
      border-bottom: 1px solid $color_secondary_text;
    }

    &::placeholder {
      color: $color_secondary_gray_light;
    }

    &--with-error {
      border-bottom-color: $color_primary;
    }

    &--with-tooltip {
      padding-right: 2.8rem;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }

      & + .text-input__label {
        top: 0;
        font-size: 0.8em;
        color: $color_secondary_gray;
        opacity: 0.9;

        .text-input__optional-label {
          font-size: 0.8em;
          opacity: 0;
        }
      }
    }

    &--with-warning {
      color: $color_primary;
    }
  }



  &--has-changes {
    font-weight: 500;

    &::after {
      position: absolute;
      top: 0.6rem;
      left: 0;
      display: block;
      width: 100%;
      height: 3.8rem;
      background-color: $color_white;
      content: '';
      opacity: 0.85;
      z-index: -1;
    }
  }

  &--with-default-value {
    color: $color_secondary_gray_light;
  }

  &__label {
    position: absolute;
    left: 0;
    top: 1.8rem;
    max-height: 1.6rem;
    font-size: 1em;
    line-height: 2.1rem;
    text-align: center;
    color: $color_secondary_gray_light;
    white-space: nowrap;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__label--visible {
    top: 0;
    font-size: 0.8em;
    line-height: 2.1rem;
    color: $color_secondary_gray;
    opacity: 0.9;

    .text-input__optional-label {
      font-size: 0.8em;
      opacity: 0;
    }
  }

  &__label--with-error {
    bottom: 4rem;
  }

  &__optional-label {
    font-size: 0.8em;
    transition: opacity 0.3s;
  }

  &--locked {

    .new-text-input__input-field {
      padding-left: 0.4rem;
      padding-right: 0.4rem;

      &[value=''] {
        pointer-events: none;
      }
    }

    .new-text-input__input-field--with-changes {
      color: $color_primary;
    }

    .new-text-input__input-field--with-warning  {
      font-weight: 500;
    }

    .new-text-input__label {
      left: 0.4rem;
      pointer-events: none;
    }

    &::after {
      position: absolute;
      left: 0;
      top: -0.3rem;
      display: block;
      width: 100%;
      height: 4.8rem;
      background-color: $color_secondary_gray_light;
      content: '';
      opacity: 0.15;
      z-index: 0;
    }

    .new-text-input__input-field {
      border-color: transparent;
    }
  }

  &--locked-textarea {
    &::after {
      height: 100%;
    }
  }
}


.textarea-input {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $color_secondary_text;

  &--textarea {
    box-sizing: border-box;
    padding-bottom: 0.3rem;
    resize: none;

    &::after {
      position: absolute;
      left: 0;
      top: -0.3rem;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color_secondary_gray_light;
      content: '';
      opacity: 0.15;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__input-field {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    font-size: 1em;
    font-family: $font-main;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: 0.15px;
    text-align: inherit;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color_secondary_gray_light;
    color: inherit;
    outline: none;
    cursor: text;

    &--textarea {
      resize: none;

      &::after {
        position: absolute;
        left: 0;
        top: -0.3rem;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color_secondary_gray_light;
        content: '';
        opacity: 0.15;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::-webkit-credentials-auto-fill-button {
      position: absolute;
      top: -100vh;
      visibility: hidden;
      pointer-events: none;
    }

    &:hover {
      border-bottom: 1px solid $color_border_gray_light;
    }

    &:focus-within {
      border-bottom: 1px solid $color_secondary_text;
    }

    &::placeholder {
      color: $color_secondary_gray_light;
    }

    &--with-error {
      border-bottom: 1px solid $color_primary;
    }

    &--with-tooltip {
      padding-right: 2.8rem;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }

      & + .text-input__label {
        top: 0;
        font-size: 0.8em;
        color: $color_secondary_gray;
        opacity: 0.9;

        .text-input__optional-label {
          font-size: 0.8em;
          opacity: 0;
        }
      }
    }

    &--with-warning {
      color: $color_primary;
    }
  }



  &--has-changes {
    font-weight: 500;

    &::after {
      position: absolute;
      top: 0.6rem;
      left: 0;
      display: block;
      width: 100%;
      height: 3.8rem;
      background-color: $color_white;
      content: '';
      opacity: 0.85;
      z-index: -1;
    }
  }

  &--with-default-value {
    color: $color_secondary_gray_light;
  }

  &__label {
    position: absolute;
    left: 0;
    top: 1.8rem;
    max-height: 1.6rem;
    font-size: 1em;
    line-height: 2.1rem;
    text-align: center;
    color: $color_secondary_gray_light;
    white-space: nowrap;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__label--visible {
    top: 0;
    font-size: 0.8em;
    line-height: 2.1rem;
    color: $color_secondary_gray;
    opacity: 0.9;

    .text-input__optional-label {
      font-size: 0.8em;
      opacity: 0;
    }
  }

  &__label--with-error {
    bottom: 4rem;
  }

  &__optional-label {
    font-size: 0.8em;
    transition: opacity 0.3s;
  }

  &--locked {

    .textarea-input__input-field {
      padding-left: 0.4rem;
      padding-right: 0.4rem;

      &[value=''] {
        pointer-events: none;
      }
    }

    .textarea-input__input-field--with-changes {
      color: $color_primary;
    }

    .textarea-input__input-field--with-warning  {
      font-weight: 500;
    }

    .textarea-input__label {
      left: 0.4rem;
      pointer-events: none;
    }

    &::after {
      position: absolute;
      left: 0;
      top: -0.3rem;
      display: block;
      width: 100%;
      height: 4.8rem;
      background-color: $color_secondary_gray_light;
      content: '';
      opacity: 0.15;
      z-index: 0;
    }

    .textarea-input__input-field {
      border-color: transparent;
    }
  }

  &--locked-textarea {
    &::after {
      height: 100%;
    }
  }
}
