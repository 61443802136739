@import "assets/styles/variables.scss";

.illustrations {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 296px;
}

.item:nth-child(1) {
  max-width: 664px;
  margin-left: 0;
  overflow: hidden;
  transform: translate(0px, -0.08%);
}

.item:nth-child(2) {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 2px;
  padding: 0;
}

.item:nth-child(3) {
  max-width: 506px;
  margin-right: 2.5rem;
}

.line {
  width: 100%;
  height: 296px;
  background-image: url('../../assets/icons/line.svg');
  background-repeat: repeat-x;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
}
