@import "assets/styles/variables.scss";

.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-top: 1rem;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    content: '';
    width: calc(100vw - 10px); // 10px -scrollbar width
    height: 1px;
    background-color: $color_secondary_gray_light;
    transform: translateX(-50%);
    opacity: 0.5;
  }

  &__home-link {
    display: block;
    outline: none;
  }

  &__logo {
    width: 8rem;
    height: 54.43px;
    margin-right: 1rem;
  }

  &__title {
    margin-left: 2.2rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
    white-space: nowrap;
  }

  &__top-line {
    display: flex;
    align-items: center;
  }

  &__side-controls {
    display: flex;
    align-items: center;
    margin-left: auto;

    & .search {
      border-bottom: 1px solid $color_secondary_gray_light;

      &__input {
        line-height: 2.2rem;
        color: $color_secondary_gray_light;
        background-color: transparent;
      }
    }
  }

  &__search {
    margin-right: 1.5rem;
  }

  &__login-controls {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &__login-link {
    display: inline-flex;
    color: $color_secondary_gray;
    transition: all .2s;
    outline: none;

    &:hover {
      text-decoration: none;
    }

    &:before {
      display: inline-flex;
      width: 1px;
      height: 1.4rem;
      margin: 0 10px;
      background-color: $color_secondary_gray;
      vertical-align: middle;
      content: "";
    }

    &--role {
      text-transform: uppercase;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: $color_secondary_gray_dark;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &__navigation-list {
    display: flex;
    margin-top: 1rem;
  }

  &__navigation-item {
    padding: 0 1.7rem;
    text-transform: uppercase;


    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--with-expand-section {
      :global(.expand-button__title--active) {
        color: $color_secondary_text
      }
      padding: 0;
    }

    &:hover {
      color: $color_primary;
    }
  }

  &__navigation-link {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 3.6rem;
    text-decoration: none;
    color: inherit;
    outline: none;

    &--active {
      position: relative;
      color: $color_secondary_text;
      cursor: default;
      pointer-events: none;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $color_primary;
        z-index: 1;
      }
    }
  }
}
