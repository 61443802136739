@import "src/assets/styles/variables";

.empty-tab__title {
  font-size: 3rem;
  line-height: 3.6rem;
}

.empty-tab__description {
  margin-top: 1.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
}
