@import "assets/styles/variables.scss";

.legend {
  padding: 0.8rem 1.6rem;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  color: $color_secondary_text;
}
