.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 144rem;
  transition: opacity 0.3s ease 0s;
  grid-template-rows: max-content;
  grid-auto-rows: auto;
  -webkit-box-flex: 1;
  flex-grow: 1;
  opacity: 1;
}

.loader {
  width: 100%;
}
