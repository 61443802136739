.spinner-container {
  flex-grow: 1;
  z-index: 1;

  &--withOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .6);
  }
}

.loadedContent {
  height: 100%;
}
