@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color_overlay_background;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialogContent {
  background-color: $color_white;
  border-radius: 8px;
  max-width: 81.6rem;
  padding: 4.5rem 4.2rem 4.8rem 4.0rem;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: $header_text_color;
}

.closeButton {
  @include modal-close-button();
  top: 0;
  right: 0;
  transform: translate(-100%, 100%);
  background: none;
}

.closeButton:hover {
  color: $color_black;
}

.dialogBody {
  padding: 2rem 0;
}

.confirmButton {
  @include confirm-button();
}

.container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
}
