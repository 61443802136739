@import "assets/styles/variables.scss";

.filter {
  &-sticky {
    background-color: #f2f2f2;
    position: sticky;
    top: 10rem;
    padding-top: 2rem;
    z-index: 10;
  }
  &__controls-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

  &__clear-filter {
    flex-shrink: 0;
  }

  & .action-button {
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: $color_primary;
  }
  & .calendar {
    &__clear-button {
      align-self: center;
    }
  }
}
