@import 'assets/styles/mixins.scss';
@import 'assets/styles/variables.scss';

@mixin disabled {
  &[data-disabled='true'] {
    background-color: $color_secondary_gray_light;
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.counter {
  @include counter();
}

.textField {
  display: inline-block;
  padding: 0.5rem 0;
  min-height: 2.3rem;
  border-bottom: 1px solid $textarea_border;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
  color: $color_secondary_text;
  cursor: pointer;
  @include text-ellipsis();

  & > a {
   @include text-ellipsis();
  }
}

.name {
  width: 19rem;
}

.country {
  width: 9rem;
}

.billingAgent {
  width: 10rem;
}

.salutation, .notification, .rateCard {
  width: 15rem;

  @include disabled;
}

.email {
  width: 22rem;

  @include disabled;
}

.processed {
  display: flex;
  justify-content: center;
  width: 6rem;
}

.emailed {
  display: flex;
  justify-content: center;
  width: 7rem;
}

.status {
  width: 14rem;
  padding-right: 0;
}

.inputError {
  & > input {
    border-bottom: 1px solid $error_message_text_color;
  }
}

.viewOnly {
  cursor: pointer;

  :global{
    .select-item__single-value {
      pointer-events: none;
    }

    .select-item {
      pointer-events: none;
    }

    input[type="text"] {
      pointer-events: none;
    }

    button[type="button"] {
      pointer-events: none;
    }

    .form-select{
      cursor: pointer;
    }

    .select-item__indicators {
      display: none;
      pointer-events: none;
    }
  }
}
