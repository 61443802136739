@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/EtelkaText/EtelkaText.eot'),
  url('~assets/fonts/EtelkaText/EtelkaText.ttf'),
  url('~assets/fonts/EtelkaText/EtelkaText.svg'),
  url('~assets/fonts/EtelkaText/EtelkaText.woff') format('woff'),
  url('~assets/fonts/EtelkaText/EtelkaText.woff2') format('woff2');
}

@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/EtelkaMedium/EtelkaMedium.eot'),
  url('~assets/fonts/EtelkaMedium/EtelkaMedium.woff') format('woff'),
  url('~assets/fonts/EtelkaMedium/EtelkaMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Etelka Medium';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/EtelkaMedium/EtelkaMedium.eot'),
  url('~assets/fonts/EtelkaMedium/EtelkaMedium.woff') format('woff'),
  url('~assets/fonts/EtelkaMedium/EtelkaMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Etelka Text';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/EtelkaText/EtelkaText.eot'),
  url('~assets/fonts/EtelkaText/EtelkaText.ttf'),
  url('~assets/fonts/EtelkaText/EtelkaText.svg'),
  url('~assets/fonts/EtelkaText/EtelkaText.woff') format('woff'),
  url('~assets/fonts/EtelkaText/EtelkaText.woff2') format('woff2');
}
