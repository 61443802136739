@import 'assets/styles/variables.scss';

.form-date-picker {
  position: relative;
  font-size: 1.5rem;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 1.6rem;
    font-size: 0.8em;
    line-height: 1.4em;
    text-align: left;
    color: $color_secondary_gray;
    white-space: nowrap;
    opacity: 0.9;

    &--locked {
      padding-left: 0.4rem;
    }
  }
}

.form-checkbox {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $color_secondary_text;

  &__label {
    position: relative;
    display: inline-flex;
    padding-bottom: 2.4rem;
    font-size: 1em;
    line-height: 1.6rem;
    letter-spacing: 0.4px;
    color: $color_secondary_gray;
    white-space: nowrap;
    cursor: pointer;

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 1.3em;
      height: 1.3em;
      display: block;
      background-image: url('../../assets/img/icon-checkbox.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: opacity 0.3s;
    }

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 1.3em;
      height: 1.3em;
      background-image: url('../../assets/img/icon-checkbox-checked.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &--checked {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 1;
      }
    }

    &--is-locked {
      pointer-events: none;
      cursor: default;

      &::before {
        background-color: rgba(187,189,192, 0.15);
        background-image: url('../../assets/img/icon-checkbox-locked.svg');
      }
      &::after {
        background-color: rgba(187,189,192, 0.15);
        background-image: url('../../assets/img/icon-checkbox-checked-locked.svg');
      }
    }
  }

  &__input-field {
    width: 0;
    height: 0;
    line-height: 0;
    display: none;
  }
}

.form-slider {
  &__placeholder {
    display: inline-block;
    padding-left: 0.1rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.25px;
    white-space: nowrap;
    color: $color_secondary_gray_light;
  }

  &__placeholder:not(:last-child) {
    min-width: 2.2rem;
  }

  &__native-input-field {
    width: 0;
    height: 0;
    line-height: 0;
    display: none;
  }
}

.pop-up-menu {
  display: none;
  position: fixed;
  background-color: #232325;
  border-radius: 10px;
  z-index: 100;
  display: flex;
 button {
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
  &:nth-child(1) {
    border-right: 1px solid silver;
  }
 }
}
