@import 'assets/styles/mixins.scss';
@import "assets/styles/variables.scss";
@import "layouts/user-management/mixins.module.scss";

@mixin disable-select {
  :global {
    .form-select .select-item--is-disabled .select-item__value-container {
      padding-left: 0;
    }

    .select-item .select-item__control--is-disabled {
      cursor: not-allowed;
    }

    .select-item__control {
      border: none;
    }

    .form-select .select-item--is-disabled::after {
      background-color: transparent;
    }
  }
}

@mixin disabled-form {
  input {
    border: none;
    pointer-events: none;
  }

  & div[data-id="controls"] {
    pointer-events: none;

    button {
      cursor: not-allowed;
      text-decoration: none;
    }
  }

  @include disable-select();
}

[data-disabled="true"] {
  cursor: not-allowed;
}

.list {
  @include user-management-grid();
  align-items: flex-start;
  min-height: 5rem;
  border-bottom: none;

  li {
    min-width: 100%;
    max-width: 15rem;
  }

  & input {
    font-size: 14px;
  }

  :global {
    @include select-styles();

    p.select-item__label {
      color: $header_optional_text_color;
    }

    .error-message {
      margin-top: 0.1rem;
      font-size: 1rem;
    }

    .error-message__title {
      display: block;
      font-size: inherit;
    }

    .select-item__single-value {
      font-size: 14px;
    }

    .select-item__control {
      box-sizing: content-box;
      min-height: 2.3rem;
      padding: 0.5rem 0;
    }

    .select-item__label {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
      font-size: 14px;
      line-height: 2.1rem;
      color: $color_secondary_text;
    }

    .select-item__input {
      font-size: 14px;
      line-height: 2.1rem;
      color: $color_secondary_text;

      & input {
        font-family: $font-main;
      }
    }
  }
}

.disabledForm {
  cursor: not-allowed;
}

.disabledList {
  opacity: 0.5;
  @include disabled-form();
}

.activeUser {
  & > li[data-id="Email"] {
    cursor: not-allowed;

    input {
      border: none;
      pointer-events: none;
    }
  }
}

.isAdmin {
  & > li[data-id="Role"] {
    @include disable-select();
  }
}

.controlBlock {
  & div[data-id="controls"] {
    pointer-events: none;

    button {
      cursor: not-allowed;
      text-decoration: none;
    }
  }
}

.deactivatedUser {
  cursor: not-allowed;

  & > li:not([data-id="Deactivate"]) {
    opacity: 0.5;

    @include disabled-form();
  }
}

div[data-id="controls"] {
  display: flex;
  gap: 0.8rem;
  opacity: 1;
  cursor: initial;

  button {
    max-height: 3.2rem;
  }

  & > [data-id="Save"], [data-id="Resend Invite"], [data-id="Send Invite"] {
    @include primary-button();
    width: 100%;
    max-width: 100%;
  }

  & > [data-id="Cancel"] {
    @include secondary-button();
    width: 100%;
  }

  & > [data-id="Deactivate"], [data-id="Reactivate"] {
    @include tertiary-button();
  }
}

.logHistoryIcon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  background-color: $color_background;
  padding: 0.2rem 0.4rem;

  path {
    fill: $color-silver;
  }

  &:hover {
    path {
      fill: $color-tundora;
    }
  }
}

.inputField {
  position: relative;
  z-index: 1;
}
