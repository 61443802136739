@import 'assets/styles/mixins.scss';
@import 'assets/styles/variables.scss';

.button {
  @include u-button-reset();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  text-decoration: none;
  cursor: pointer;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  box-sizing: border-box;
  border: 2px solid $color-text-primary;
  border-right-color: $color-white;
  border-radius: 50%;
  width: 2.0rem;
  height: 2.0rem;
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
