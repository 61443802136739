@import "assets/styles/variables.scss";

.alphabet-filter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  & .action-button {
    padding: 0.2rem 0.5rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    text-decoration: none;
    color: $color_secondary_text;

    &--active,
    &:hover {
      background-color: $color_primary;
      color: $color_white;

      &:first-child {
        background-color: transparent;
        color: $color_primary;
      }
    }
  }

  & .alphabet-filter__all {
    text-transform: none;
  }
}
