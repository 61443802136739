@import 'assets/styles/variables.scss';

.checkbox {
  position: relative;
  margin-right: 5rem;
  padding: 0 2.8rem 0 2.9rem;
  border: 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: $color_secondary_gray_light;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;

  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: '';
    width: 1.8rem;
    height: 1.8rem;
    display: block;
    background-image: url('../../assets/img/icon-checkbox.svg');
    background-size: 100%;
    background-repeat: none;
    transform: translateY(-50%);
    opacity: 0.3;
    transition: opacity 0.3s;
  }

  &:focus-within,
  &:hover {
    color: $color_secondary_text;

    &::before {
      opacity: 1;
    }
  }

  &--active {
    color: $color_secondary_text;

    &::before {
      background-image: url('../../assets/img/icon-checkbox-checked.svg');
      opacity: 1;
    }
  }
}
