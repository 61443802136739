@import "assets/styles/variables.scss";


.dropzone {
  box-sizing: border-box;
  margin-top: 2rem;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 1.6rem 1.6rem;
    border: 1px dashed $color_secondary_gray_light;
    outline: none;

    &--with-file {
      pointer-events: none;
    }

    &--focused,
    &:hover {
      background-color: $color_main_background_0_4;
     }
  }

  &__placeholder {
    width: 100%;
    text-align: center;
  }

  &__file-title {
    display: grid;
    grid-template-areas: "icon name" " icon additional";
    grid-template-columns: max-content 1fr;
    grid-column-gap: 1rem;
    width: 100%;
  }

  &__icon {
    grid-area: icon;
  }

  &__title-additional-content {
    margin-top: 0.6rem;
  }

  &__file-name {
    width: 100%;
    max-width: 18.8rem;
    border-bottom: 2px solid #BBBDC0;
    font-size: 1.2rem;
    line-height: 2.4rem;
    letter-spacing: 0.15px;
    white-space: nowrap;
    color: $color_secondary_text;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__placeholder-title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $color_secondary_gray_light;

    &--with-border {
      margin-top: 1.6rem;
      padding: 0.3rem 2.4rem;
      border: 1px solid $color_secondary_gray;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.6rem;
      letter-spacing: 0.25px;
      text-transform: uppercase;
      color: $color_secondary_gray;

      &:active,
      &:hover {
        border-color: $color_primary;
        color: $color_white;
        background: $background_buttons;
        box-shadow: $shadow_buttons;
      }
    }
  }

  &__error {
    margin-top: 1rem;
    color: $color_primary;
  }
}
