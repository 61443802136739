@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.form {
  width: 100%;
  max-width: 480px;
  color: $dashed_button_hover_color;

  :global {
    .text-input {
      position: relative;
    }

    .text-input__input-field {
      margin-top: 0.5rem;
      padding: 1rem 1rem 1rem 4.2rem;
      border: 1px solid $textarea_border;
      background-color: $color_white;
      border-radius: 4px;

      &:hover {
        border-bottom-color: $textarea_border;
      }

      &::placeholder {
        color: $header_optional_text_color;
      }

      &--with-error {
        border: 1px solid $primary_button_color;

        &:hover {
          border-bottom-color: $primary_button_color;
        }
      }
    }
  }

  & .left {
    position: absolute;
    top: 13px;
    left: 7px;
  }

  & .right {
    position: absolute;
    top: 14px;
    right: 9px;
  }

  & label {
    font-size: 13px;
  }
}

.item {
  margin-bottom: 19px;

  input {
    padding: 8px 8px 8px 40px !important;
    font-size: 15px;
    max-height: 40px;
  }
}

.button {
  @include primary-button();
  @include centred-control();
  position: relative;
  width: 100%;
  font-size: 14px;
  text-decoration: none;
  margin-top: 3.6rem;
  line-height: 1;
  padding: 11px;

  span {
    padding: 0.2rem 0;
  }

  img {
    position: absolute;
    top: 50%;
    left: 41%;
    transform: translate(-50%, -50%);
  }

  &:disabled {
    padding: 11px;
  }

  &:hover {
    box-shadow: none;
  }
}

.error {
  color: $primary_button_color;
}
