@import "assets/styles/variables.scss";

.tooltip {

  &__icon {
    position: absolute;
    display: flex;
    top: 2.1rem;
    right: 0.6rem;
    max-height: 1.9rem;
    outline: none;
    cursor: pointer;
  }

  &__description {
    line-height: 1.6rem;
    font-size: 1.3rem;
    color: $color-white;
  }

  .__react_component_tooltip {
    width: 100%;
    max-height: 11.3rem;
    padding: 2rem 1.1rem;
    border-radius: 0;
    text-align: center;
    background: $color_background__tooltip;
    opacity: 1;
    box-sizing: border-box;

    &:after {
      border-bottom-color: $color_background__tooltip;
      border-bottom-width: 0.9rem;
      border-left: 0.6rem solid transparent;
      border-right: 0.6rem solid transparent;
    }

    &.place-bottom {
      position: absolute;
      top: 3.3rem !important;
      left: 0 !important;

      &:after {
        top: -0.6rem;
        right: 0.8rem;
        left: auto;
        border-bottom-color: $color_background__tooltip;
      }
    }

    &.place-top {
      position: absolute;
      top: -10.1rem !important;
      left: 0 !important;

      &:after {
        top: 11.3rem;
        right: 0.8rem;
        left: auto;
        border-top-color: $color_background__tooltip;
      }
    }
  }
}
