@import "assets/styles/variables.scss";
@import "assets/styles/variables.scss";

.client-upload-document-form {
  position: relative;
  margin-top: 1.6rem;
  padding: 1.5rem 3.2rem 1.6rem;
  box-sizing: border-box;
  background: $color_background_light;

  &--with-uploading {
    pointer-events: none;
  }

  .form-select {
    .error-message {
      margin-top: -0.6rem;
    }

    .select-item__dropdown-indicator {
      max-width: 18px;
    }

    .select-item__value-container {
      max-width: 100%; // calc(100% - 18px);
    }
  }

  .select-item {
    font-size: 1.4rem;
    margin: 1.1rem 0;
  }

  &__wrapper {
    display: grid;
  }

  &__title {
    margin-bottom: 1.1rem;
    font-family: $font-main;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color_secondary_gray_dark;
  }

  &__button {
    width: 100%;
    margin-top: 2.1rem;
    padding: 7.2px 2.4rem;
  }


  .date-picker {

    &__input-divider,
    &__date-input {
      font-size: 1.4rem;
    }
  }

  &--isMsaType {
    position: relative;
    display: grid;
    grid-column-gap: 1.6rem;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1.1fr);
    min-width: 26.4rem;
    margin-top: 1rem;

    .select-item {
      margin: 0;
    }

    .form-select__select-control {
      padding: 0 1rem;
    }

    .text-input {
      position: static;
    }

    .react-date-picker__inputGroup {
      font-size: 1.4rem;
    }

    .tooltip__icon {
      left: calc((100% / 3.1) * 2 - 1.8rem); // according grid-template-columns of --isMsaType
      bottom: 0.4rem;
      transform: translateX(-1rem);
    }

    .__react_component_tooltip {
      &.place-bottom {
        &:after {
          left: calc((100% / 3.1) * 2 - 1.8rem); // according grid-template-columns of --isMsaType
        }
      }

      &.place-top {
        top: 10.4rem;

        &:after {
          left: calc((100% / 3.1) * 2 - 1.8rem); // according grid-template-columns of --isMsaType
        }
      }
    }
  }

  .form-select__toggle-button,
  .text-input__input-field {
    font-size: 1.4rem;
  }

  .react-date-picker__inputGroup {
    font-size: 1.4rem;
  }

  & .text-input {
    font-size: 1.4rem;
  }
}



.dropzone {
  box-sizing: border-box;
  margin-top: 2rem;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 9rem;
    border: 1px dashed #bbbdc0;
    cursor: pointer;
  }
}
