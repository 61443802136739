@import "assets/styles/mixins.scss";
@import 'assets/styles/variables.scss';

.addFileButton {
  @include dashed-action-button();
  width: 100%;
}

.error {
  margin-top: 1rem;
  color: $color_primary;
}
