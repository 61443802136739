@import 'assets/styles/variables.scss';

.empty_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 15rem;
  height: 100%;
  color: $color_secondary_gray_light;
  opacity: 0;
  animation-name: fade;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;

  &__title {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  &__description {
    margin-top: 1.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}
