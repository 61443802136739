@import 'assets/styles/variables.scss';

.star-icon {
  display: flex;
  justify-content: center;
  width: 100%;

  &__image {
    max-width: 2.1rem;
    cursor: pointer;
  }
}
