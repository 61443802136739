@import 'assets/styles/variables.scss';

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.errorCard {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: left;
  padding: 1rem;
  font-size: 1.4rem;
  color: $color_primary;
  background-color: $color_cherub;
  border: 1px solid $color_primary;
  border-radius: 4px;
  line-height: 1;

  button {
    display: inline-flex;
  }
}

.clearButton {
  display: flex;
}

.errorMessage {
  width: 100%;
  padding-right: 8px;
}
