@import "assets/styles/variables.scss";

.date-inputs {
  display: flex;

  &__item-divider {
    margin: 0 0.25ch;
    font-family: inherit;
    font-size: 1em;
    line-height: 2.4rem;
    letter-spacing: inherit;
  }

  &__item {
    max-width: 2ch;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    line-height: 2.4rem;
    text-align: left;
    letter-spacing: inherit;
    background-color: transparent;
    outline: none;
    color: inherit;

    &--year {
      max-width: 4ch;
    }

    &::placeholder {
      font-size: inherit;
      letter-spacing: 0.13em;
      text-align: left;
      color: $color_secondary_gray_light;
    }

    &::-webkit-contacts-auto-fill-button {
      position: absolute;
      right: 0;
      display: none !important;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__empty {
    color: $placeholder_color;
  }
}

