@import 'assets/styles/variables.scss';
@import 'assets/styles/mixins.scss';

.layout {
  margin-top: 2.4rem;
  display: grid;
  grid-row-gap: 2.4rem;
}

.editProfileLayout {
  display: grid;
  grid-column-gap: 4.8rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: none;
  grid-template-areas: "generalInfo generalInfo careerBreakInfo dismissalInfo";
}

.placeholder {
  @include list-items-placeholder()
}

.detailsWrapper {
  column-gap: 8rem;
  margin: 2.4rem auto;
  padding: 0 5.6rem;
}

.createEmployeeWrapper {
  box-sizing: border-box;
  padding: 0 5.6rem;
  width: 100%;
}

.tabs {
  & [role="tablist"] {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid $tab_list_border_color;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1.6rem;
      height: 3.2rem;
      letter-spacing: 0;
      text-align: left;
      text-transform: capitalize;
      border: none;
      background-color: transparent;
      border-radius: 4px;
      font-family: Etelka, sans-serif;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.4rem;
      color: $color-text-primary;
      cursor: pointer;

      &[data-active="true"] {
        background-color: $color_primary;
        color: $color_white;
        cursor: default;

        &:hover {
          background-color: $tab_button_hover_color;
        }
      }
    }
  }
}

.formControlsStyles {
  :global {
    .details-form__button {
      &.button--accept {
        @include primary-button();
        @include centred-control();
      }

      &.button--cancel {
        @include secondary-button();
        @include centred-control();
      }

      &.button--disabled {
        @include primary-button();
        @include centred-control();
        border: none;
      }

      width: 100%;
    }
  }
}

.workbookFormStyles {
  :global {
    .data-list__form-title-controls-wrapper {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: 1fr;
      grid-row-gap: 2rem;

      button.data-list__button {
        @include dashed-action-button();
        justify-content: center;
        align-items: center;
      }
    }

    .button--with-loader {
      min-height: 3.2rem;
    }

    .details-form__units-wrapper {
      display: grid;
      grid-template-columns: 10rem;
      grid-row-gap: 1.6rem;
      justify-content: center;

      & .action-button--with-body {
        @include button();
        @include centred-control();
      }
    }
  }
}

.formLayoutStyles {
  :global{
    .details-form__form-title-controls-wrapper {
      padding-left: 0;
    }

    .details-form__form-template {
      padding-left: 0;
    }

    .details-form{
      & > form {
        padding: 0;
      }
    }

    .plain-text {
      & > p {
        margin: 0;
        width: 100%;
      }
    }

    .form-date-picker {
      width: 100%;
      margin: 0;
    }

    .details-form__form-component {
      padding: 0 1.6rem;

      & .text-input__input-field {
        text-overflow: ellipsis;
      }

      & .text-input--locked {
        cursor: not-allowed;
      }
    }

    .text-input {
      width: 100%;
      margin: 0;
    }

    .form-select {
      width: 100%;
      margin: 0;
    }

    .form-switcher {
      max-width: 100%;
      margin: 0;
    }

    .button--with-loader {
      min-height: 3.2rem;
    }

    .action-button.button.button--cancel.action-button--with-body {
      @include secondary-button();
      text-align: center;
      max-height: 3.2rem;
      line-height: 1.6rem;
      margin-right: 0;
    }
  }
}
