@import "assets/styles/variables.scss";

.content {
  width: 100%;
  margin: 14rem 0;
  font-family: "Etelka", Arial, sans-serif;
}

.form {
  margin-top: 4.2rem;
  margin-bottom: 5.6rem;
}

.header {
  gap: 2.1rem;
}

.subHeading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.footer {
  text-align: center;
  font-size: 14px;
  color: $header_text_color;

  span {
    display: block;
    padding-bottom: 0.5rem;
  }
}

.link {
  color: $header_text_color;
}

.instructions {
  margin: 5rem 0;
}

.error {
  align-items: center;
  margin-bottom: 2rem;
}

.resetButton {
  text-decoration: underline;
  margin-bottom: 1rem;
}

.spinner {
  opacity: 0.5;
}
