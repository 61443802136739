@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.inputField {
  @include input-field();
  @include text-ellipsis();

  &::placeholder {
    color: $header_optional_text_color;
  }

  &[aria-required="true"] {
    border-color: $color_primary;
  }

  &:not([data-disabled="true"]):hover {
    border-color: $textarea_border_hover;
  }
}

.error {
  margin-top: 0.4rem;
  color: $color_primary;
}

.isLocked {
  pointer-events: none;
}
