@import 'assets/styles/mixins.scss';
@import "assets/styles/variables.scss";

.form {
  position: relative;
  box-sizing: border-box;
  display: grid;
  max-height: 100%;

  :global {
    @include select-styles();
    .form-select .select-item__label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.4rem;
      color: $color_secondary_gray_light
    }

    .form-select .select-item--is-disabled::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1.6rem;
      transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      border: 0.1rem solid $color_secondary_gray_light;
      border-top: none;
      border-right: none;
      pointer-events: none;
    }

    .select-item {
      box-sizing: content-box;
    }

    .select-item__control {
      margin-bottom: 0;
      width: 100%;
      align-self: center;
      padding: 0.45rem 0;
      min-height: 2.3rem;
    }
  }
}

.filter {
  padding-top: 1.6rem;
  height: 3.6rem;
}

.formActionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  bottom: 0;
  height: 6rem;
  background-color: $color-secondary_background;
  font-size: 2.4rem;
  font-weight: 500;
  padding: 0.8rem 0;
}
