@import "assets/styles/variables.scss";

.details-form {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  height: 100%;

  &::-webkit-scrollbar {
    position: absolute;
    width: 5px;

    &-track {
      background-color: $color_background_dark;
      box-shadow: $shadow_scrollbar;
    }

    &-thumb {
      min-height: 10px;
      border-radius: 5px;
      background-color: $color_secondary_gray_light;
    }
  }

  &__form-component {
    width: 100%;
    height: 100%;
  }

  &__table {
    width: 100%;

    & .table__content-wrapper {
      padding-bottom: 0;
    }

    & .row-wrapper {
      & .grid-unit {
        &:first-child {
          padding-left: 1.6rem;
        }
      }
    }
  }

  &__field-array-line {

    &-placeholder {
      margin: 1rem 1.8rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color_secondary_gray_light;
    }
  }

  & .date-picker:hover {
    .date-picker {
      &__date-wrapper--with-clear {
        padding-left: 0;
      }

      &__toggle-button--with-clear {
        padding-right: 0;
      }
    }
  }

  &__form-template {

    &--table-view {

      .text-input--locked,
      .date-picker--locked,
      .select-item--is-disabled {
        &::after {
          box-sizing: border-box;
          top: 0.5rem;
          height: 4.0rem;
          background-color: $color_secondary_gray_light_0_15;
          border-bottom: 1px solid $color_secondary_gray_light_0_4;
          opacity: 1;
        }
      }

      .text-input--has-changes-locked {
        &:after {
          background-color: $color_secondary_gray_0_15;
        }
      }
    }
  }

  &__form-title-controls-wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    min-height: 2.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: inherit;
    color: $color_secondary_text;
    cursor: default;
  }

  &__button {
    box-sizing: border-box;
    width: 100%;
    max-height: 3.2rem;
    margin-top: 0.6rem;
    padding: 0.75rem 0;
    font-size: inherit;
    text-align: center;
    transition: min-width 0s;

    &--set-default {
      width: auto;
      margin: 0 0 0 1.6rem;
      padding: 0.3rem 1rem;
      font-size: 1.3rem;
      line-height: 1.6rem;
      letter-spacing: 0.25px;
    }
  }

  &__form-heading {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color_secondary_text;
    cursor: default;
    overflow-x: hidden;
  }

  & .calendar-wrapper {
    padding-top: 0.8rem;
  }

  & .table {
    position: relative;
    min-height: 4rem;

    & .spinner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 11rem;
    }
  }

  & .Toastify__toast:first-child {
    margin-top: 0;
  }

  & .Toastify__toast:last-child {
    margin-bottom: 0rem;
  }
  & .Toastify__toast{
    margin-top: 1.4rem;
  }
}

.form-switcher {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 4.4rem;
  // padding-top: 0.8rem;

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__label-title {
    font-size: 1.4rem;
    line-height: 2.1rem;
    mix-blend-mode: normal;
    color: $color_secondary_gray_dark;
  }

  &__status-title {
    min-width: 2.2rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    mix-blend-mode: normal;
    text-align: right;
    color: $color_secondary_gray;
    transition: color 0.3s;

    &--checked {
      color: $color_primary;
    }
  }

  &__icon-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    height: 100%;
  }

  &__icon-track {
    display: flex;
    align-items: center;
    width: 3.4rem;
    height: 1.4rem;
    margin-right: 1.2rem;
    border-radius: 1.4rem;
    background-color: $color_background_toggle_button;
    transition: background-color 0.3s;

    &--checked {
      justify-content: flex-end;
      background-color: rgba(214, 31, 38, 0.5);
    }
  }

  &__icon-knob {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: $color_main_background;
    transition: background-color 0.3s;

    &--checked {
      background-color: $color_primary;
    }
  }

  &--locked {
    pointer-events: none;

    .form-switcher {
      &__icon-track {
        background-color: #ccc;

        &--checked {
          background-color: rgba(88, 89, 91, 0.5);
        }
      }

      &__icon-knob {
        background-color: $color_main_background;

        &--checked {
          background-color: $color_secondary_background_gray_dark;
        }
      }

      &__status-title {
        color: $color_secondary_gray_light;

        &--checked {
          color: $color_secondary_text;
        }
      }
    }
  }
}

.form-switcher-top {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 4.4rem;
  margin-bottom: -3rem;
  padding-top: 10px;
  z-index: 10;

  &__label-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__label-title-top {
    font-size: 1.4rem;
    line-height: 2.1rem;
    mix-blend-mode: normal;
    color: $color_primary;
  }

  &__icon-wrapper-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-left: 20px;
  }
}

.plug {
  border-bottom: 1px solid #bbbdc0;
  height: 45px;
}
