@import 'assets/styles/variables.scss';

.viewbox {
  &__viewbox-link {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    align-self: stretch;
    width: 100%;
    height: auto;
    font-family: inherit;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }


  &__icon {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
}
