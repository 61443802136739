@import "assets/styles/mixins.scss";

.controlsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.6rem;
  align-items: center;
}

.primaryButton {
  @include primary-button();
}

.secondaryButton {
  @include secondary-button();
}

.tertiaryButton {
  @include tertiary-button();
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.2rem;
}
