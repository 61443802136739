@import 'assets/styles/variables.scss';
@import 'assets/styles/mixins.scss';
@import 'layouts/user-management/mixins.module.scss';

.filters {
  margin-bottom: 0.2rem;
  border: 1px solid $color_secondary_gray-light;
  @include user-management-grid;
  gap: 1px;
  background-color: $color_secondary_gray-light;

  & > div {
    background: $color_secondary_background;
    :global {
      .filter-select__menu{
        min-width: calc(100% + 0.4rem);
      }
      .filter-select__placeholder,
      .filter-select__menu-notice--no-options,
      .filter-select__input {
        font-family: "Etelka", Arial, sans-serif;
        font-size: 1.3rem;
      }
    }
  }
}

.container {
  margin-top: 0.8rem;
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.6rem;
}

.clearFilter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  min-height: 1.6rem;
}

.clearFilterButton {
  @include tertiary-button();
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: $color_primary;
  padding: 0;
  width: max-content;
  &:hover {
    text-decoration: none;
  }
}
