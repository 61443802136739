@import "assets/styles/variables.scss";

.form {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  max-width: 495px;
  margin: 0 auto;

  :global {
    .text-input {
      position: static;
      width: 64px;
      height: 64px;

      &__input-field {
        height: 100%;
        margin-top: 0;
        text-align: center;
        font-size: 3.8rem;
        background-color: $color_white;
        border: 1px solid $color-silver-chalice;
        border-radius: 4px;
        color: $header_text_color;

        &:focus {
          border: 1px solid  $color-tundora;
        }
      }

      &__input-field--with-changes {
        border: 1px solid  $color-tundora;
      }

      &--has-changes::after {
        background-color: transparent;
      }

      &:nth-child(3) {
        margin-right: 1.8rem;

        &:after {
          content: '';
          width: 1.1rem;
          height: 0.2rem;
          background-color: $textarea_border;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &:nth-child(4) {
        margin-left: 1.8rem;
      }
    }
  }
}
