@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.startDate,
.endDate {
  width: 12.8rem;
  :global {
    .calendar--locked::after {
      display: none;
    }
  }
};

.technicalMentorId {
  width: 71rem;
};

.actions {
  min-width: 21.8rem;
};

.bodyStyles {
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  align-items: flex-start;
  min-height: 5rem;

  :global {
    @include select-styles();

    .calendar {
      align-items: center;
      padding: 0.4rem 0;
    }
  }

  & [data-mode="view"]:not(.endDate, .startDate),
  & [data-mode="view"].lock {
    :global {
      .select-item__single-value {
        pointer-events: none;
      }
      .select-item {
        pointer-events: none;
      }
      .select-item__control {
        border-bottom: none;
      }
      .select-item__indicator{
        display: none;
      }
      .calendar {
        pointer-events: none;
        align-items: center;
        padding: 0.4rem 0;
        border-bottom: none;
      }
      .calendar__toggle-button {
        display: none;
      }
    }
  }
}

.disabledForm {
  cursor: not-allowed;
}

.disabledList {
  opacity: 0.5;
  pointer-events: none;
}
