@import "assets/styles/variables.scss";

.controls {
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 0 1.6rem;
  color: $color_secondary_gray;
  background-color: $color_background_light;
  z-index: 2;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__main-controls {
    display: flex;
    align-items: center;
  }

  &__additional-controls {
    margin-top: 1.6rem;

    .checkbox {
      font-family: $font-main;
    }
  }

  &__create-button {
    min-width: auto;
    margin: 0 2.5rem;
    padding: 0.7rem 1rem;
    letter-spacing: 1.25px;
    text-decoration: none;
  }

  &__clear-filter {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    & .action-button {
      padding: 0;
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: $color_primary;
      opacity: 0;
      animation-name: fade;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
    }
  }
}


@keyframes fade {
from {
  opacity: 0;
}

to {
  opacity: 1;
}
}

@media screen and (max-width: 900px) {
  .controls {
    &__alphabet-filter {
      display: none;
    }
  }
}

.clients_controls {
  & .alphabet-filter {
      .action-button {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }
}
