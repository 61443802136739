@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.table {
  display: block;
  width: 100%;

  & thead {
    position: sticky;
    top: 0;
    z-index: 1;
    display: block;
    background-color: $color_secondary_background_light;
    color: $header_text_color;
  }

  & tr {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 4fr;
  }

  & tbody {
    display: block;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-color;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: $scrollbar-track-color;
    }
  }

  & th,
  & td {
    color: $color_secondary_text;
    font-size: 1.3rem;
    line-height: 2.4rem;
    border-bottom: 0.1rem solid $color_secondary_gray_light;
  }

  & th div,
  & tr td {
    padding: 0.8rem;
  }
}

.tableHeader {
  position: relative;
  text-align: left;
  font-weight: 500;
  transition: 0.3s;
}
