@import 'assets/styles/variables.scss';

.wrapper {
  position: absolute;
  left: -100vw;
  top: -100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 136rem;
  margin: 0 auto;
  padding: 0 4rem;
  opacity: 0;
  transition: opacity 0.3s;

  &--grow {
    flex-grow: 1;
  }

  &--visible {
    position: static;
    opacity: 1;
  }
}
