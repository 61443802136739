@import 'assets/styles/variables.scss';

.confirm-action-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //max-width: 656px;
  height: 100%;

  &__title {
    margin-bottom: 1.6rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  &__description {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &__controls {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
  }

  &__button {
    width: 100%;
    margin: 0 1.2rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    .spinner-container {
      padding-bottom: 0;
    }
  }

  &__wrapper {
    .textarea {
      margin-top: 2.8rem;
    }
  }
}
