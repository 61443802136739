@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.user {
  width: 14.4rem;
};

.comment {
  width: 83.2rem;
};

.actions {
  min-width: 20.8rem;
};

.bodyStyles {
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  align-items: flex-start;
  min-height: 5rem;
  padding-bottom: 0.4rem;
}

.disabledForm {
  cursor: not-allowed;
}

.disabledList {
  opacity: 0.5;
  pointer-events: none;
}
