@import "variables.scss";

@mixin u-button-reset() {
  all: unset;
  outline: none;
}

@mixin modal-close-button {
  position: absolute;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin: 0;
  padding: 0;
  border: none;
  transition: all 0.3s;

  &::before,
  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color_button_background;
    content: '';
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {

    &::before,
    &::after {
      box-shadow: $shadow_buttons;
    }
  }
}

@mixin dashed-action-button () {
  box-sizing: border-box;
  padding: 0.5rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  font-family: Etelka Medium, sans-serif;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.7px;
  color: $dashed_button_color;
  border-radius: 4px;
  text-align: center;
  border: 1px dashed $dashed_button_color;

  [data-id='PlusIcon'] {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: transparent;
    border-color: $dashed_button_hover_color;
    color: $dashed_button_hover_color;
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: $dashed_button_disable_color;
    color: $dashed_button_disable_color;
  }
}

@mixin button() {
  box-sizing: border-box;
  padding: 0.6rem 0;
  font-family: Etelka Medium, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.7px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 4px;
}

@mixin primary-button() {
  @include button();
  color: $color_white;
  background: $primary_button_color;

  &:hover {
    background: $primary_button_hover_color;
    border-color: $primary_button_hover_color;
  }

  &:active {
    background: $primary_button_active_color;
    border-color: $primary_button_active_color;
  }

  &:disabled {
    padding: 0.6rem 0.8rem 0.6rem 0.8rem;
    cursor: not-allowed;
    background-color: $primary_button_disable_color;
    color: $primary_button_disable_color_text;
  }
}

@mixin secondary-button() {
  @include button();
  border: 1px solid $secondary_button_color;
  color: $secondary_button_color;
  background: transparent;

  &:hover {
    border-color: $secondary_button_hover_color;
    background: transparent;
  }

  &:active {
    border-color: $secondary_button_active_color;
    background: transparent;
  }
}

@mixin tertiary-button() {
  @include button();
  font-size: 1.4rem;
  text-transform: none;
  font-family: Etelka, sans-serif;
  color: $tertiary_button_color;
  letter-spacing: 0;
  text-decoration: underline;
  background: transparent;

  &:hover {
    text-decoration: none;
  }

  &:active {
    border-color: $tertiary_button_color;
  }
}

@mixin select-styles () {
  .form-select {
    width: 100%;

    & .select-item {
      display: flex;
      flex-grow: 1;
      align-items: center;
      min-height: auto;
    }
  }

  .select-item__control {
    width: 100%;
    align-self: center;
    padding: 0.4rem 0;
    min-height: 3.1rem;
  }

  .select-item__value-container {
    padding: 0;
  }

  .select-item__dropdown-indicator {
    position: relative;
    top: -0.5rem;
  }

  .select-item__single-value {
    font-family: "Etelka Text", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
  }

  .error-message--visible {
    margin-top: 0.4rem;
  }

  .select-item__option {
    &:hover {
      background-color: $select_item_active_background_color;
    }

    &:active {
      background-color: $select_item_active_background_color;
      color: $select_item_active_color;
    }
  }
}

@mixin centred-control () {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@mixin text-ellipsis () {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin scrollbar() {
  scrollbar-width: thin;
}

@mixin input-field {
  padding: 0.5rem 0;
  display: flex;
  min-height: 2.3rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid $textarea_border;
  font-family: 'Etelka Text', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
  text-align: left;
  background-color: transparent;
  color: $color_secondary_text;
  outline: none;
}

@mixin font-regular-medium {
  font-family: "Etelka Medium", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

@mixin form-header-text {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Etelka Medium", sans-serif;
  line-height: 1.6rem;
  letter-spacing: 0.15px;
}

@mixin button-old-design {
  position: relative;
  box-sizing: border-box;
  padding: 1.2rem 2.4rem;
  border: 1px solid $color_secondary_text;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: $color_secondary_text;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  letter-spacing: 0.125rem;
}

@mixin confirm-button {
  @include button-old-design();
  border-color: $color_primary;
  color: $color_primary;
  margin-top: 5rem;
  width: 160px;

  &:active, &:hover {
    border-color: $color_primary;
    background-color: $color_primary;
    color: $color_white;
  }
}

@mixin list-items-placeholder {
  margin: 1rem 1.8rem;
  color: $color_secondary_gray_light;
  min-height: auto;
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;

  :global {
    .empty_message__description {
      margin-top: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $empty_message_description_color;
    }
  }
}

@mixin counter {
  position: fixed;
  bottom: 0;
  width: 100%;
  font-family: Etelka, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  white-space: pre;
  background-color: $color_white;
  color: $color_secondary_gray;

  & .counterContent {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 144rem;
    margin: 0 auto;
    padding: 0.4rem 4rem;
  }
}
