@import "../../../node_modules/reset-css/sass/_reset.scss";
@import "./fonts.css";
@import "./variables.scss";
@import "./mixins.scss";

html {
  min-height: 100%;
  color: $color_secondary_gray;
  font-size: 10px;
}

body {
  box-sizing: border-box;
  width: 100%;
  min-width: 1023px;
  height: 100%;
  margin: 0;
  font-family: $font-main;
  overflow-y: scroll;
  background-color: $color_secondary_background;

  &.unauthorized {
    background-color: $color_background_light;
  }


  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    &:horizontal {
      height: 5px;
    }

    &-track {
      background-color: $color_background_dark;
      box-shadow: $shadow_scrollbar;
    }

    &-thumb {
      min-height: 30px;
      border-radius: 5px;
      background-color: $color_secondary_gray_light;
    }
  }

  * {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

#root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 136rem;
  height: 100%;
  min-height: 100vh;
}

.button {
  @include button-old-design();

  &--cancel {
    border-color: $color_primary;
    color: $color_primary;

    &:active,
    &:hover {
      border-color: $color_primary;
      color: $color_white;
      background: $background_buttons;
    }
  }

  &--disabled {
    color: $color_secondary_gray_light;
    background: transparent;
    border-color: $color_secondary_gray_light;
    pointer-events: none;
    opacity: 0.6;
  }

  &--accept {
    border-color: $color_primary;
    color: $color_white;
    background-color: $color_primary;

    &:active,
    &:hover {
      border-color: $color_primary;
      color: $color_white;
      background: $background_buttons;
      box-shadow: $shadow_buttons;
    }
  }

  &--close {
    @include modal-close-button();
  }

  &--toggle {
    position: absolute;
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin: 0;
    padding: 0;
    border: none;
  }

  &--with-loader {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
    pointer-events: none;
    cursor: default;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 9px;
  }
}

@media screen and (max-width: 1280px) {
/*   html {
    font-size: 7px;
  } */
}
