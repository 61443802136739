@import "assets/styles/variables.scss";

.client-details-controls {
  width: 100%;
  min-height: 3.7rem;
  padding-top: 1.2rem;
  color: $color_secondary_gray;
  background-color: $color_background_light;

  &--new-client {
    color: $color_secondary_gray_light;
  }

  &__button {
    width: 100%;
    min-width: auto;
    padding: 0.75rem 1.6rem;
    margin-top: 2rem;
    text-align: center;
    cursor: pointer;
  }

  &__button--fixed-size {
    margin: 0 0 0 2.5rem;
    //padding-left: 5.5rem;
    //padding-right: 5.5rem;
  }

  &__button-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .order-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.8rem 1.2rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: $color_secondary_text;

    &--active {
      color: $color_primary;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12), 0 3px 3px rgba(0, 0, 0, 0.14);
      transition: all 0.3s ease-in;
    }

    &__icon {
      display: flex;
      margin-bottom: 0.2rem;

      & path {
        fill: $color_primary;
      }
    }
  }

  &__drop-down-menu {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    outline: none;

    &--opened {
      &::after {
        position: absolute;
        bottom: -5px;
        right: 0;
        display: block;
        width: 100%;
        height: 15px;
        background-color: $color_background_light;
        content: '';
        z-index: 6;
      }
    }

    &--has-warning {

      .order-button {
        color: $color_primary;
      }
    }

    &--dismissed {

      .order-button {
        color: $color_secondary_gray_light;
      }
    }
  }

  &__expand-section-title {
    margin-top: 0.4rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-transform: none;
    white-space: nowrap;

    &:first-child {
      margin-top: 0;
    }

    &.warning {
      white-space: break-spaces;
      margin-top: 1rem;
    }
  }

  &__expand-section {
    top: 100%;
    left: -100vw;
    position: absolute;
    padding: 2.8rem 2rem;
    background-color: $color_background_light;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12), 0 3px 3px rgba(0, 0, 0, 0.14);
    opacity: 0;
    z-index: 5;

    &--opened {
      animation-name: fadeDetails;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
    }
  }

  & .title {
    margin-right: 1rem;
    color: $color_secondary_text;
  }

  & .controls-wrapper {
    display: flex;
    min-height: 3.7rem;
  }

  & .go-back-link,
  .go-back-link__arrow {
    font-family: $font-main;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    text-decoration: none;
    color: $color_secondary_gray;
  }

  & .go-back-link__container {
    align-self: flex-start;
    margin-bottom: 0.6rem;
  }

  & .go-back-link {
    &:hover {
      text-decoration: underline;
    }
  }

  & .star-icon {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    width: unset;
  }

}

@keyframes fadeDetails {
  from {
    left: 0;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}
