@import 'assets/styles/variables.scss';

.expansion-summary {
  &__icon {
    width: 1.3em;
    height: 1.2em;
    margin-right: 1em;
  }

  &__control-and-label-container {
    display: flex;
    align-items: center;
  }

  &__control {
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: inherit;

    &--disabled {
      cursor: default;
    }
  }

  &__summary-container {
    display: flex;
    font-size: 1.3rem;
  }

  &__items-separator {
    color: $color_secondary_gray_light;
    padding: 0 1ch;
  }

  &__label {
    color: inherit;
  }

  &__item-value {
    min-width: 2ch;

    &--dimmed {
      color: $color_secondary_gray_light;
    }
  }
}
