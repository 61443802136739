@import 'assets/styles/variables.scss';

.plain-text {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;

  &--is-multi-line {
    white-space: normal;
  }

  &--isEmpty {
    color: $color_secondary_gray_light;
  }

  & p {
    margin-bottom: 0.6rem;
  }

  & strong {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  & em {
    margin-bottom: 0.5rem;
    font-style: italic;
  }

  & li {
    margin: 0 1.3rem;
  }

  & ul,
  & ol {
    margin: 1rem 0;
  }

  & ul li {
    list-style-type: disc;
  }

  & ol li {
    list-style-type: decimal;
  }

  &:hover {
    overflow: visible;
  }
}
