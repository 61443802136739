@import 'assets/styles/variables.scss';

.title {
  font-size: 2.6rem;
  line-height: 3rem;
  white-space: nowrap;
  color: inherit;
  background-color: inherit;

  &--dark {
    color: $color_secondary_text;
  }
}
