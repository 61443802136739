@import "src/assets/styles/variables";

.tabs {
  padding: 0 2.4rem;
  font-family: inherit;
  height: calc(100% - $header_height);
  &__list {
    display: flex;
    gap: 0.8rem;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;
    font-family: inherit;
    background-color: transparent;
    transition: background-color 0.3s;
    overflow: hidden;
    text-overflow: ellipsis;

    &--active {
      background-color: $color_secondary_background;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    & > [data-reach-tab][data-selected] {
      border-bottom-color: transparent;
    }
  }
}

.tab {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 4.1rem;
  padding: $primary_padding_y $primary_padding_x 0 $primary_padding_y;
  text-align: left;
  border: 1px solid $tabs_list_border_color;
  transition: border-color 0.1s;
  cursor: pointer;

  &:after {
    position: relative;
    top: 2px;
    left: calc(100% + $primary_padding_x);
    content: "";
    display: block;
    width: 1rem;
    height: 1px;
    background-color: $tab_border_color;
  }

  &:last-child:after {
    display: none;
  }

  &--selected {
    border-color: $color_primary;
    border-bottom: none;
    cursor: default;
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
  }

  &__title {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
    color: $color_secondary_gray_dark;

    &--selected {
      text-decoration: none;
      color: $color_primary;
    }

    &--disabled {
      text-decoration: none;
      color: $color_secondary_gray_dark;
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__counter{
    padding-left: 0.5rem;
    color: $color_secondary_gray_light;
  }
}

.tab__panels {
  height: 100%;
}

.tab__panel {
  height: 100%;
}

