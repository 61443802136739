@import 'assets/styles/variables.scss';
@import 'assets/styles/mixins.scss';

.primaryButton {
  @include primary-button();
}

.secondaryButton {
  @include secondary-button();
}

.primaryButton, .secondaryButton {
  width: 28rem;
  height: 3.6rem;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & .active {
    justify-content: space-between;
  }
}

.formActions {
  display: flex;
  gap: 5rem;
  width: 100%;
}
