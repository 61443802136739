@import 'assets/styles/variables.scss';

.form-select {
  .select-item {
    width: 100%;
    min-height: 4.5rem;
    font-size: inherit;

    &__multi-value {
      position: relative;
      margin: 0;
      margin-right: 0.8rem;
      padding: 0;
      background-color: transparent;

      &__label {
        padding: 0;
        font-size: 1.5rem;
        font-family: "Etelka", Arial, sans-serif;
        font-weight: inherit;
        line-height: 2.4rem;
        letter-spacing: 0.15px;
      }

      &__remove {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        cursor: pointer;
        outline: none;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          display: block;
          width: 100%;
          height: 1px;
          background-color: $color_secondary_text;
          transform: translateY(-1px);
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:active,
        &:hover {
          background-color: transparent;

          &::after {
            opacity: 1;
          }
        }

        & svg {
          display: none;
        }
      }

      &:not(:nth-last-child(2))::after {
        content: ';';
        font-size: 1.5rem;
        font-family: "Etelka", Arial, sans-serif;
        font-weight: inherit;
        line-height: 2.4rem;
        letter-spacing: 0.15px;
      }
    }

    &__button {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: calc(100% - 1.8rem);
      padding: 0.4rem 2.4rem;
      transform: translate(-50%, -0.8rem);
    }

    &__menu {
      box-sizing: border-box;
      width: 100%;
      min-width: 7rem;
      margin: 0;
      border-radius: 0;
      z-index: 11;

      &::-webkit-scrollbar {
        position: absolute;
        width: 5px;

        &-track {
          background-color: $color_secondary_background_light;
          box-shadow: none;
        }

        &-thumb {
          min-height: auto;
          border-radius: 5px;
          background-color: darken($color_secondary_background_light, 10%);
        }
      }
    }

    &__menu-list {
      padding: 0;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      background-color: hsl(0,0%,100%);

      &::-webkit-scrollbar {
        position: absolute;
        width: 5px;

        &-track {
          background-color: $color_secondary_background_light;
          box-shadow: none;
        }

        &-thumb {
          min-height: auto;
          border-radius: 5px;
          background-color: darken($color_secondary_background_light, 10%);
        }
      }

      &--is-multi {
        margin-bottom: 4.5rem;

        .select-item__option {
          padding: 0 2.5rem 0 4rem;

          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: '';
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            background-image: url('../../assets/img/icon-checkbox.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            transform: translate(1.1rem, -50%);
          }

          &--is-selected {
            &::before {
              background-image: url('../../assets/img/icon-checkbox-checked.svg');
            }
          }
        }
      }
    }

    &__control {
      flex-wrap: nowrap;
      min-height: 4.5rem;
      padding: 0;
      border: none;
      border-bottom: 1px solid $color_secondary_gray_light;
      border-radius: 0;
      background-color: transparent;
      outline: 0;

      &:hover {
        border-bottom: 1px solid $color_border_gray_light;
        box-shadow: none;
      }

      &--is-focused {
        border-bottom: 1px solid $color_secondary_text;
        box-shadow: none;
      }

      &--menu-is-open {
        .select-item__dropdown-indicator {
          transform: rotate(-180deg) translateY(-0.6rem);
        }
      }

      &--is-focused {
        .select-item {
          &__placeholder {
            display: none;
          }
        }
      }
    }

    &__dropdown-indicator {
      padding: 0;
      transition: all 0.3s;
      transform: translateY(0.6rem);

      path {
        fill: $color_secondary_gray_light;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      margin: 0;
      padding-left: 0.2rem;
    }

    &__value-container {
      box-sizing: border-box;
      max-width: calc(100% - 20px);
      padding: 1.8rem 0 0;
      overflow: visible;
    }

    &__single-value {
      margin: 0;
      pointer-events: all;
    }

    &__placeholder,
    &__single-value {
      position: relative;
      padding: 0;
      font-size: 1em;
      font-family: $font-main;
      font-weight: inherit;
      line-height: 1.7em;
      letter-spacing: 0.15px;
      transform: translateY(0);
    }

    &__input,
    &__placeholder {
      color: $color_secondary_gray_light;
    }

    &__input {
      font-size: 1.5rem;
      line-height: 2.4rem;
      letter-spacing: 0.15px;

      & input {
        font-family: $font-main;
      }
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      line-height: 2.1rem;
      color: #58595b;
      opacity: 0.9;
      transition: all 0.3s;

      &--hidden {
        top: 1.8rem;
        font-size: 1em;
        font-family: $font-main;
        font-weight: inherit;
        line-height: 1.7em;
        letter-spacing: 0.15px;
        color: $color_secondary_gray_light;
        opacity: 0;
      }
    }


    &__option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      outline: none;
    }

    &__option {
      position: relative;
      width: 100%;
      padding: 0 0.8em;
      border-bottom: 1px solid rgba(85, 86, 90, 0.2);
      font-size: 1em;
      line-height: 4rem;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &--is-selected,
      &--is-focused {
        background-color: $color_main_background_0_4;

        &:active {
          background-color: $color_main_background_0_4;
        }
      }
      &--is-selected {
        color: inherit;
      }
    }

    &--is-disabled {
      .select-item {
        &__control {
          border-color: transparent;
        }

        &__indicators {
          display: none;
        }

        &__label {
          padding-left: 0.4rem;
          line-height: 1.9rem;
        }

        &__value-container {
          max-width: 100%;
          padding-left: 0.4rem;
        }

        &__single-value {
          top: calc(50% - 2px);
          color: $color_secondary_text;
          pointer-events: all;
        }

        &__placeholder {
          left: 0.4rem;
        }
      }

      &::after {
        position: absolute;
        left: 0;
        top: -0.3rem;
        display: block;
        width: 100%;
        height: 4.8rem;
        background-color: $color_secondary_gray_light;
        content: '';
        opacity: 0.15;
        z-index: -0.5;
      }
    }

    &__link {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--with-error {
    .select-item__control {
      border-color: $color_primary;
    }
  }
}
