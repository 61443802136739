@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.channelPartnerToggle {
  grid-column: span 2;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.controlButton {
  position: relative;
  box-sizing: border-box;
  padding: 0 2.4rem;
  border: 1px solid $color-primary;
  height: 3.2rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: $color-primary;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  @include font-regular-medium();

  &:not(:disabled):active,
  &:not(:disabled):hover  {
    border-color: $color-primary;
    color: $color-white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), $color-primary;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
  }

  &:disabled {
    border-color: $color-secondary_gray;
    color: $color-secondary_gray;
    cursor: not-allowed;
  }
}
