@import 'src/assets/styles/variables';

.screen-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 2.2rem;
  text-align: center;
  color: $color_secondary_gray_light;

  &__title {
    font-size: 1.65em;
    line-height: 1.9em;
  }
  &__description {
    font-size: 1.8rem;
    line-height: 2.4rem;
    max-width: 280px;
    @media screen and (max-width: 1440px) {
      max-width: 250px;
    }

    &--full {
      max-width: 100%;
    }
  }
  &__controls {
    display: flex;
    padding-top: 0.8rem;
    font-size: 1.5rem;
    button.action-button {
      width: 15.2rem;
      margin: 0 1.2rem;
    }
  }
}
