.dropzone {
  &__placeholder-title {
    &--disabled {
      margin-top: 1.6rem;
      padding: 0.3rem 2.4rem;
      border: 1px solid #bbbdc0;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.6rem;
      letter-spacing: 0.25px;
      text-transform: uppercase;
      color: #bbbdc0;
    }
  }
  &__wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between !important;
    .custom-link a {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    &--disabled:hover {
      background-color: #fff !important;
    }
  }
}
