@import "assets/styles/variables.scss";

.errorMessage {
  background: $color_background_light;

  & div {
    width: 100%;
    max-width: 136rem;
    margin: 2rem auto;
    font-size: 1.6rem;
    padding: 0;
    line-height: 2.4rem;
  }

  & button {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    color: $color_primary;
    text-decoration: none;
  }
}
