@import 'assets/styles/variables.scss';

.send-review-modal {

  &__title {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  & .form-select {
    grid-area: select;
  }
  & .text-input {
    grid-area: title;
  }

  &__form {
    display: grid;
    grid-template-areas: "select select" "title title" "textarea textarea" "button button";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.4rem;
    grid-row-gap: 1.6rem;
    margin-top: 3rem;
  }

  &__textarea {
    grid-area: textarea;
    box-sizing: border-box;
    resize: none;
    height: 12.8rem;
    padding: 1.2rem 1rem;
    border: 1px solid $color_secondary_gray_light;
    font-family: inherit;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.15px;
    color: $color_secondary_text;
    overflow-y: scroll;
    outline: none;

    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;

      &-track {
        background-color: $color_secondary_background_light;
        box-shadow: none;
      }

      &-thumb {
        min-height: auto;
        border-radius: 5px;
        background-color: darken($color_secondary_background_light, 10%);
      }
    }
  }

  .checkbox {
    grid-area: checkbox;
  }

  &__controls {
    grid-area: button;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }

  &__button {
    width: 100%;
    margin: 0 1.2rem;


    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
