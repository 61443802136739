@import 'assets/styles/variables.scss';

.subheading {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.3rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: $color_secondary_gray;
}
