@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.header {
  display: flex;
  box-sizing: border-box;
  height: 4.6rem;
  gap: 0.5rem;
  background-color: $color-secondary_background;
}

.sort {
  transition: $transition_speed;
  max-width: 1.2rem;
  color: $color_secondary_gray_light;

  &.desc {
    transform: rotate(180deg);
  }
}

.headerColumnWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1.5rem 0 1.5rem 0.5rem;
  gap: 0.5rem;
  border-bottom: 0.1rem solid $color_secondary_gray_light;
  font-family: "Etelka Medium", Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: $color_black;
  cursor: pointer;

  & > span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.active,
  &:hover {
    color: $color_primary;

    .sort {
      color: $color_primary;
    }
  }

  &[data-sort-active="true"] {
    color: $color_primary;

    .sort {
      color: $color_primary;
    }
  }
}
