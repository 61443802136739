@import "assets/styles/variables.scss";

.Toastify {
  position: relative;
  grid-area: toastify;
  background: $color_background_light;
  z-index: 0;

  &__toast {
    display: flex;
    align-items: center;
    min-height: 5.6rem;
    margin: 0;

    &--error {
      border: 1px solid $color_primary;
      background: $color_primary_red_light;
      color: $color_primary;

      &:before {
        position: absolute;
        top: 1.25rem;
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        background-image: url('../../assets/img/icon-error-note.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
      }
    }

    &--success {
      color: #0e6c0e;
      background-color: #dafbdb;
      border: 1px solid #00cb05;

      &:before {
        position: absolute;
        top: 1.25rem;
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        background-image: url('../../assets/img/icon-success-note.png');
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
      }
    }

    &-body {
      padding-left: 3.2rem;
      font-family: $font-main;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &-container {
      position: relative;
      width: 100%;
      max-width: 136rem;
      margin: 0 auto;
      padding: 0;
      z-index: -1;

      &--top-right {
         top: 0;
         right: 0;
      }
    }
  }
}
