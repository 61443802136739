@import 'assets/styles/mixins.scss';

$containerHeight: 52vh;

.list {
  height: $containerHeight;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-auto-rows: 4rem;
  padding-bottom: 1rem;
  @include scrollbar();
}

.placeholder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: $containerHeight;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $empty_message_description_color;

  span {
    margin: 3.2rem 1.6rem;
  }
}
