@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.controls {
  :global  {
    .calendar {
      font-size: 1.6rem;
      color: $color_primary;
      & {
        display: flex;
        min-width: unset;
        align-items: center;
        margin-right: 1.6rem;
        padding-top: 0;
        border: none;
        & path {
          fill: $color_primary;
        }
      }
      & .calendar__toggle-button {
        padding-top: 0.3rem;
      }
      & .calendar__date-wrapper {
        padding-top: 0.4rem;
      }
    }

    .controls__additional-controls {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1440px) {
  :global{
    .screen-placeholder{
      & .screen-placeholder__description {
        max-width: 50rem
      }
    }
  }
}

.pdfButton {
  @include button();
  @include primary-button();
  width: 11.1rem;
  height: 4rem;
  margin-top: 1.6rem;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 1;
  }
}
