@import "assets/styles/variables.scss";

.range-input {
  display: flex;
  align-items: center;
  padding: 0 0.4rem;

  &__separator {
    font-size: 1em;
    line-height: 1.9em;
    color: $color_secondary_gray_light;
  }

  & .text-input__input-field  {
    margin-top: 0;
    text-align: center;

    &, &:hover, &:focus-within {
      margin-top: 0;
      border-bottom: 0px solid transparent;
      text-align: center;
      font-size: 1.1rem;
      padding-top: 0.6rem;
      line-height: 2.4rem;
    }

    &::-webkit-input-placeholder {
      text-align: center;
    }

    &::-moz-placeholder {
      text-align: center;
    }
  }
}
