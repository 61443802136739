@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.employmentType {
  width: 20.8rem;
};

.deliveryCenterId {
  width: 8rem;
};

.startDate,
.expirationDate,
.terminationDate {
  width: 12.8rem;
};

.file {
  width: 31.6rem;
  & > [data-id="Add File"] {
    width: 100%;
  }
};

.actions {
  min-width: 21.8rem;
};

.bodyStyles {
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  align-items: flex-start;
  min-height: 5rem;

  :global {
    @include select-styles();

    .calendar {
      align-items: center;
      padding: 0.4rem 0;
    }
  }

  & [data-mode="view"],
  & [data-mode="view"].lock {
    :global {
      .select-item__single-value {
        pointer-events: none;
      }
      .select-item {
        pointer-events: none;
      }
      .select-item__control {
        border-bottom: none;
      }
      .select-item__indicator{
        display: none;
      }
      .calendar {
        pointer-events: none;
        align-items: center;
        padding: 0.4rem 0;
        border-bottom: none;
      }
      .calendar__toggle-button {
        display: none;
      }
    }

    [data-id="Attachment View"] {
      background-color: transparent;
    }

    [data-id="Attachment Remove"] {
      display: none;
    }
  }

  & .deactivate {
    & > [data-id] {
      color: $color_secondary_gray_light;
    }

    :global {
      .select-item__single-value {
        color: $color_secondary_gray_light;
      }
    }
  }
}

.disabledForm {
  cursor: not-allowed;
}

.disabledList {
  opacity: 0.5;
  pointer-events: none;
}
