
.user-settings {
  display: grid !important;
  grid-template-areas:
  "details password"
  "devices devices";
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.4rem;
}

.user-data {
  padding: 1.6rem 0;
  grid-area: details;
}

.user-data__title {
  min-height: 2.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: rgba(0,0,0,.87);
}

.user-data__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "fullname    phone"
    "email   lastlogin"
    "title      loginip"
    "billingagent     ...";
  grid-gap: 2.4rem;
  padding-top: 16px;
}

.user-settings__table {
  grid-area: devices;
  .details-table__title {
    margin: 0;
    min-height: 2.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: rgba(0, 0, 0, 0.87);
  }
}
