@import "assets/styles/variables.scss";

.error-message {
  display: flex;
  align-items: center;
  height: auto;
  max-height: 100%;
  margin-top: 0.5rem;
  font-family: $font-main;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $color_primary;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: default;

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  &--visible {
    opacity: 1;
  }
}
