.seniority {
  padding: 1.6rem 0;
}

.title {
  padding-left: 1.6rem;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.table {
  & th div {
    padding-left: 1.6rem;
  }

  & td {
    font-size: 1.3rem;
    padding-left: 1.6rem;
  }

  & ul li:not(:first-child) {
    margin-top: 1rem;
  }
}
