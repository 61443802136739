@import "assets/styles/variables.scss";

.controls {
  :global  {
    .calendar {
      font-size: 1.6rem;
      color: $color_primary;
      & {
        display: flex;
        min-width: unset;
        align-items: center;
        margin-right: 1.6rem;
        padding-top: 0;
        border: none;
        & path {
          fill: $color_primary;
        }
      }
      & .calendar__toggle-button {
        padding-top: 0.3rem;
      }
      & .calendar__date-wrapper {
        padding-top: 0.4rem;
      }
    }

    .select {
      width: max-content;
      font-size: 1.6rem;

      & {
        color: $color_primary;
        .filter-select {
          width: auto;
          height: auto;

          &__multi-value__label {
            color: $color_primary;
          }

          &__placeholder {
            padding-left: 0;
            color: $color_primary;
          }

          &__control--is-focused {
            outline: none !important;
          }

          &__dropdown-indicator {
            path {
              fill: $color_primary;
            }
          }

          &__multi-value {
            background-color: transparent;
          }
        }

        .filter-select__control {
          cursor: pointer;
        }
      }
    }
    .controls__additional-controls {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}
