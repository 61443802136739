@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.addContractButton {
  @include dashed-action-button();
  width: 100%;
}

.fieldError {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  height: auto;
  max-height: 100%;
  font-family: "Etelka Text", Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $error_message_text_color;
  cursor: default;
}

.loader {
  width: 100%;
}

.calendar {
  color: $color_secondary_text;

  :global {
    .calendar__date-wrapper {
      font-size: 1.4rem;
    }

    .calendar__clear-button {
      margin-left: 0.4rem;
    }

    .calendar__toggle-button {
      padding-left: 0.4rem;
    }
  }
}
