@import "assets/styles/variables.scss";

.clients-error {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;


  &__title {
    margin: 29.3rem 0 3.2rem;
    font-size: 14rem;
    color: $color_primary;
  }

  &__message {
    margin-bottom: 2.4rem;
    font-size: 3rem;
    color: $color_secondary_text;
  }

  &__description {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $color_secondary_text;

    &-link {
      color: $color_secondary_text;
    }
  }
}
