@import 'assets/styles/variables.scss';

.list-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 2.4rem;
  font-family: $font-main;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  white-space: pre;
  color: $color_secondary_gray;
  background-color: $color_background_light;
  z-index: 10;

  &__text {
    padding: 0.4rem 0 0.4rem 0.6rem;
  }
}
