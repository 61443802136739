@import 'assets/styles/variables.scss';


.textarea {
  position: relative;
  grid-area: textarea;
  margin-top: 1.6rem;

  &__input {
    box-sizing: border-box;
    resize: none;
    width: 100%;
    height: 20rem;
    padding: 1.2rem 1rem;
    border: 1px solid $color_secondary_gray_light;
    font-family: inherit;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.15px;
    color: $color_secondary_text;
    overflow-y: scroll;
    outline: none;

    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;

      &-track {
        background-color: $color_secondary_background_light;
        box-shadow: none;
      }

      &-thumb {
        min-height: auto;
        border-radius: 5px;
        background-color: darken($color_secondary_background_light, 10%);
      }
    }
  }

  &__label {
    position: absolute;
    left: 1rem;
    top: 1.5rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
    color: $color_secondary_gray_light;
    white-space: nowrap;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__label--visible {
    top: 0;
    left: 0;
    font-size: 1.2rem;
    color: $color_secondary_gray;
    transform: translateY(-2rem);
    opacity: 0.9;
  }
}
