@import "src/assets/styles/variables";

.form-switcher {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 4.4rem;
  // padding-top: 0.8rem;

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__label-title {
    font-size: 1.4rem;
    line-height: 2.1rem;
    mix-blend-mode: normal;
    color: $color_secondary_gray_dark;
  }

  &__status-title {
    min-width: 2.2rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    mix-blend-mode: normal;
    text-align: right;
    color: $color_secondary_gray;
    transition: color 0.3s;

    &--checked {
      color: $color_primary;
    }
  }

  &__icon-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    height: 100%;
  }

  &__icon-track {
    display: flex;
    align-items: center;
    width: 3.4rem;
    height: 1.4rem;
    border-radius: 1.4rem;
    background-color: $color_background_toggle_button;
    transition: background-color 0.3s;

    &--checked {
      justify-content: flex-end;
      background-color: rgba(214, 31, 38, 0.5);
    }

    &--disabled {
      background-color: $color_background_toggle_button;
    }
  }

  &__icon-knob {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: $color_main_background;
    transition: background-color 0.3s;

    &--checked {
      background-color: $color_primary;
    }

    &--disabled {
      background-color: $color_main_background;
    }
  }

  &--locked {
    pointer-events: none;

    .form-switcher {
      &__icon-track {
        background-color: #ccc;

        &--checked {
          background-color: rgba(88, 89, 91, 0.5);
        }
      }

      &__icon-knob {
        background-color: $color_main_background;

        &--checked {
          background-color: $color_secondary_background_gray_dark;
        }
      }

      &__status-title {
        color: $color_secondary_gray_light;

        &--checked {
          color: $color_secondary_text;
        }
      }
    }
  }
}

.form-switcher-top {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 4.4rem;
  margin-bottom: -3rem;
  padding-top: 10px;
  z-index: 10;

  &__label-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__label-title-top {
    font-size: 1.4rem;
    line-height: 2.1rem;
    mix-blend-mode: normal;
    color: $color_primary;
  }

  &__icon-wrapper-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-left: 20px;
  }
}
