@import 'assets/styles/mixins.scss';
@import "assets/styles/variables.scss";
@import "mixins.module.scss";

.wrapper {
  padding: 0 4rem 3rem 4rem;
  margin: 0 auto;

  & [data-id="user-management-form"]:first-child {
    margin-top: 0.8rem;
  }
}

.loader {
  width: 100%;
}

.stickyHeader {
  padding-top: 3rem;
  position: sticky;
  top: 10rem;
  z-index: 2;
  background: $color_background;
}

div.placeholder {
  @include list-items-placeholder;
  justify-content: flex-start;
  align-items: flex-start;
}

.headings {
  @include user-management-grid();
  @include form-header-text();

  width: 100%;
  box-sizing: border-box;
  text-align: left;
  color: $header_text_color;

  li {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: default;
    border-bottom: 1px solid $color_secondary_gray_light;
  }

  .sortableHeading {
    cursor: pointer;
    color: $color_primary;
  }
}

.desc {
  transform: rotate(180deg);
}

.addUserButton {
  @include dashed-action-button();
  width: 100%;
}

.counter {
  @include counter();
  z-index: 10;
}
