@import "assets/styles/variables.scss";

.pagination {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.list {
  display: flex;
  align-items: center;
}

.item {
  width: 100%;
  height: 100%;

  & a,
  & button {
    display: flex;
    padding: 1rem;
    cursor: pointer;
  }

  & svg {
    width: 0.9rem;
    height: 1.2rem;
  }

  &:not(.active) {
    text-decoration: underline;
  }

}

.active {
  color: $color_primary;
}

.prev {
  transform: rotate(90deg);
}

.next {
  transform: rotate(-90deg);
}

.startIcon {
  transform: rotate(180deg);
}
