@import "assets/styles/variables.scss";

$square-size: 16rem;

.instructions {
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: center;
  width: 100%;
  color: $header_text_color;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 16px;
  color: black;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 49.6rem);
  gap: 3rem;
  width: 100%;
  min-height: 20.5rem;
}

.column {
  border: 1px solid $textarea_border;
  padding-bottom: 1.5rem;

  &:last-child {
    margin-right: 0;
  }
}

.columnTitle {
  font-size: 1.6rem;
  padding: 1.2rem 2rem;
  background: $color_background;
}

.columnList {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 2.5rem;
  margin: 2rem 0 2.5rem;
}

.children {
  padding: 0 3rem 0 2.5rem;
  margin: 3rem 0 2.5rem;
}

.code {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.columnItem {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  line-height: 1.4;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    position: relative;
    top: 5px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    background: $primary_button_color;
    border-radius: 50%;
  }
}

.link {
  color: $header_text_color;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.qrcode {
  max-width: $square-size;
  max-height: $square-size;
  margin: 0 0 1.5rem 1.5rem;

  img {
    width: $square-size;
    height: $square-size;
  }
}
