@import "assets/styles/variables.scss";

.days-off-calendar {
  display: grid;
  grid-template-columns: repeat(7, 3.2rem);
  grid-auto-rows: 3.2rem;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  &__day,
  &__weeks-names {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: $color_secondary_gray_light;
    text-transform: uppercase;
  }

  &__day {
    font-size: 1.4rem;
    color: $color_secondary_gray;

    &--holiday {
      color: $color_white;
      background-color: $color_primary;
    }

    &--weekend {
      color: $color_secondary_gray;
      background-color: $color_secondary_gray_light_0_25;
    }

    &--out-of-mount {
      color: $color_secondary_gray_light;
    }
  }
}
