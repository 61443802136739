@import 'assets/styles/variables.scss';

.loader-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $color_overlay_background;
  z-index: 20;
  overflow: auto;
}
