.expansion {
  &-panel {
    &__details-container {
      height: 0;
      max-height: 0;
      overflow: hidden;
      transition: height 0.2s ease-out;
      transition: max-height 0.5s ease-out;

      &--expanded {
        height: auto;
        max-height: 50rem;
      }
    }
  }
  &-summary-content-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #000;
    &:nth-child(2){
      justify-content: flex-start;
    }
    &:nth-child(4){
      border-left:1px solid silver;
    }
    &:nth-child(12){
      border-left:1px solid silver;
    }
    &:nth-child(15){
      border-left:1px solid silver;
    }
    &:nth-child(17){
      border-right:1px solid silver;
    }
  }
}

.total-text {
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 500;
  color: rgba(0,0,0,0.87);
  padding-right: 0;
  text-align: left;
}


