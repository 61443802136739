@import 'assets/styles/variables.scss';

.grid-unit {
  box-sizing: border-box;
  display: flex;
  min-width: 3.2rem;
  font-family: $font-main;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: inherit;
  text-align: left;

  &--with-multi-items {
    display: flex;
    box-sizing: border-box;
    // justify-content: space-between;
    padding-right: 0.8rem;

    .grid-unit:only-child {
     display: flex;
     width: 100%;
     justify-content: flex-end;
    }
  }

  &--heading {
    align-items: center;
    min-height: auto;
    padding: 1.4rem 0;
    border-bottom: 1px solid $color_secondary_gray_light;

    .order-button {
      line-height: 1.4rem;
      white-space: normal;
    }

    .plain-text {
      padding-right: 0;
      text-align: left;
      white-space: normal;
    }
  }

  &--filter {
    align-items: center;
    display: flex;
    min-height: auto;
    padding: 0;
    background-color: $color_background_light;

    &:focus-within {
      outline: 1px solid $color_primary;
    }
  }
  .calendar {
    padding: 0;
    border: none;
    font-size: 1.2rem;

    &__input {
      &-divider {
        margin-right: 0.2ch;
        color: $color_secondary_gray_light;
      }
    }

    &__date-wrapper {
      padding: 0.4rem 0 0 0.4rem;
    }

    &__toggle-button {
      padding-top: 0.6rem;
    }
    &__expand-section {
      transform: translateY(0.4rem);
    }
  }

  &--active {
    background-color: $color_background_dark;
    outline: 1px solid $color_primary;
  }

   & .form-switcher {
    min-height: auto;
    padding: 0;
  }
}
