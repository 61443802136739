@import 'assets/styles/variables.scss';

textarea {
  border: none;
  padding: 0;
  resize: none;
  width: 100%;
  overflow-y: hidden;
  font-family: 'Etelka Text', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
  text-align: left;
  background-color: transparent;
  color: $color_secondary_text;

  transition: border-color 150ms ease;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0.4rem;
  cursor: pointer;
  color: $textarea_icon_color;
}

.textareaContainer {
  min-height: 4rem;
  padding-right: 2.4rem;
  position: relative;
  border-bottom: 1px solid $textarea_border;

  &[aria-required="true"] {
    border-color: $color_primary;
  }

  &:not([data-disabled="true"]):hover {
    border-color: $textarea_border_hover;
  }
}

textarea{
  &:focus {
    outline: none;
    border-color: transparent;
  }

  &::placeholder {
    margin: 0 auto;
    color: $textarea_placeholder_color;
  }
}

.textareaError {
  margin-top: 0.4rem;
  color: $color_primary;
}

.viewMode {
  cursor: not-allowed;

  & textarea {
    pointer-events: none;
  }
}
