@import "assets/styles/variables.scss";

.details-tabs {
  padding-top: 1.2rem;
  font-family: inherit;
  background-color: $color_background_light;

  &__single-tab {
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;
    padding: 0 0 1.6rem;
    border: none;
    font-family: inherit;
    background-color: transparent;
    outline: none;
    transition: background-color 0.3s;
    overflow: hidden;
    text-overflow: ellipsis;

    &--active {
      border-top: 1px solid $color_primary;
      background-color: $color_secondary_background;
    }

    &:hover {

      .details-tabs__title {
        color: $color_primary;
      }
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;

      &:hover {

        .details-tabs__title {
          color: inherit;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 8rem;
    padding: 0.8rem 1.6rem;
    text-align: left;
    border: 1px solid $color_secondary_gray_light;
    transition: border-color 0.3s;
    cursor: pointer;

    &--active {
      border-color: transparent;
      cursor: default;
    }

    &--disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  &__title {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: capitalize;
    text-decoration: underline;
    white-space: nowrap;
    color: $color_secondary_gray_dark;
    transition: all 0.3s;

    &--active {
      text-decoration: none;
      color: $color_primary;
    }

    &--disabled {
      text-decoration: none;
      color: $color_secondary_gray_dark;
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__label {
    max-width: calc(100% - 1.6rem);
    font-family: inherit;
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color_secondary_gray;

    &--isEmpty {
      color: $color_secondary_gray_light;
    }

    &--is-active {
      color: $color_primary;
    }

    &--disabled {
      text-decoration: none;
      color: $color_secondary_gray;
    }
  }
}
