@import "assets/styles/variables.scss";

.header {
  box-sizing: border-box;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  gap: 3.2rem;
  height: 4.8rem;
  font-family: Etelka Medium, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.15px;
  text-align: left;
  color: $header_text_color;

  & .listItem {
    border-bottom: 0.1rem solid $color_secondary_gray_light;
  }
}

.listItem {
  display: flex;
  align-items: center;
}

.item {
  padding: 0;

  & > * {
    width: 100%;
  }
}

.active {
  background-color: $color_white;
}

.headerContent {
  display: grid;
  height: 3.3rem;

  & > [data-id="Optional Header Label"]{
    font-family: "Etelka Text", sans-serif;
    color: $header_optional_text_color;
  }
}

.formsContainer {
  & > form:first-child {
    margin-top: 0.8rem;
  }
}
