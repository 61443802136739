@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.content {
  font-family: 'Etelka Text', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
  text-align: left;
  color: $color_secondary_text;
  @include text-ellipsis();
  & a {
    color: $color_black;
    &:hover {
      text-decoration: none;
    }
  }
}
