@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.screen-placeholder__controls {
  .action-button {
    @include button();
    @include primary-button();
    width: 11.1rem !important;
    height: 4rem;
    margin-top: 1.6rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: center;

    &:disabled {
      cursor: not-allowed;
      box-shadow: none;
      opacity: 1;
    }

    &:nth-child(2) {
      background-color: transparent;
      color: $primary_button_color;
    }
  }
}

