@import "assets/styles/variables.scss";

.container {
  display: flex;
  min-height: 3.4rem;
}

.filter {
  display: flex;
  min-width: 3.2rem;
  background-color: $color_secondary_background_light;
  font-family: "Etelka", Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: left;
  padding-right: 0.4rem;

  &:focus-within {
    outline: 1px solid $color_primary;
    outline-offset: 0;
  }
}

.active {
  background-color: $color_background_dark;
}
