@import 'assets/styles/variables.scss';

.modal-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $color_overlay_background;
  z-index: 20;

  &__wrapper {
    overflow: auto;
    scrollbar-color: darken($color_secondary_background_light, 10%) $color_secondary_background_light;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;

      &-track {
        background-color: $color_secondary_background_light;
        box-shadow: none;
      }

      &-thumb {
        min-height: auto;
        border-radius: 5px;
        background-color: darken($color_secondary_background_light, 10%);
      }
    }
  }

  &__button--close {
    top: 0;
    right: 0;
    transform: translate(-100%, 100%);
  }

  .Toastify__toast-container {
    margin-bottom: 1.6rem;
  }
}
