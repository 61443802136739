@import "assets/styles/variables.scss";

.data-list {
  flex-grow: 1;
  padding: 1.6rem 0;

  &__title {
    min-height: 3.2rem;
    margin-left: 1.6rem;
    font-size: 1.8rem;
    line-height: 3.4rem;
    text-align: inherit;
    color: $color_secondary_text;
    cursor: default;
  }

  &__no-data-message {
    margin: 1rem 1.8rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color_secondary_gray_light;
  }

  & .text-input__label {
    font-size: 1.2rem;

    &--visible {
      opacity: 0;
    }
  }

  & .text-input__input-field:focus {
    & + .text-input__label {
      opacity: 0;
    }
  }

  &__button {
    margin-left: 1.6rem;
    padding: 0.7rem 1.2rem;
  }

  &__form-title-controls-wrapper {
    display: flex;
    align-items: center;
  }

  .text-input--locked .text-input__input-field {
    border-color: $color_secondary_gray_light;
  }

  .form-select .select-item--is-disabled .select-item__control {
    flex-wrap: nowrap;
    border-color: $color_secondary_gray_light;
  }

  .text-input--locked::after,
  .form-select .select-item--is-disabled::after {
    position: absolute;
    left: 0;
    top: 0.9rem;
    display: block;
    width: 100%;
    height: 3.6rem;
    background-color: #bbbdc0;
    content: '';
    opacity: 0.15;
    z-index: 0;
  }

  .text-input--textarea::after {
    height: calc(100% - 1.2rem);
  }

  .button--with-loader {
    margin-top: 5.5rem;
  }
}
