@import "assets/styles/variables.scss";

.document-generate-form {
  position: relative;
  padding: 2rem 3.2rem 1.6rem;
  box-sizing: border-box;

  &__wrapper {
    background: $color_background_light;
  }

  &__title {
    margin-bottom: 1.3rem;
    font-family: $font-main;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color_secondary_gray_dark;
  }

  &__button {
    width: 100%;
    margin-top: 1.6rem;
    padding: 7.2px 2.4rem;
  }

  & .text-input {
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }

  .react-date-picker__inner {
    margin-top: 3rem;
  }

  .form-select__toggle-button,
  .text-input__input-field {
    font-size: 1.4rem;
  }

  .date-picker {
    &__input-divider,
    &__date-input {
      font-size: 1.4rem;
    }
  }
}
