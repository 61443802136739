@import "assets/styles/variables.scss";

.calendar {
  position: relative;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  padding-top: 2rem;
  border-bottom: 1px solid $color_secondary_gray_light;
  font-family: inherit;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus-within {
    border-color: $color_border_gray_light;
  }

  &__expand-section {
    min-width: 100%;
    background-color: $color_white;
    box-shadow: $shadow_buttons;
    z-index: 2;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    min-width: 4.8rem;
    margin-right: 1rem;
  }

  &__date-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    letter-spacing: inherit;
    outline: none;

    &--month {
      border-bottom: none;
    }
  }

  &__toggle-button {
    box-sizing: border-box;
    display: flex;
    margin: 0;
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all 0.5s;
  }

  &__clear-button {
    position: relative;
    min-width: unset;
    margin-left: 0.2rem;
    opacity: 0;
    pointer-events: none;
  }

  &-date-picker {
    display: flex;

    padding: 1.2rem 0;

    &__views-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 29rem;

      &--with-weeks {
        min-width: unset;
        margin-right: 2rem;
      }
    }

    &__weeks-controls-wrapper {
      display: grid;
      grid-template-rows: 3rem 3rem auto 3rem 3rem;
      align-items: center;
    }

    &__weeks-days {
      display: grid;
      grid-auto-rows: 3.2rem;
      grid-row-gap: 0.4rem;
      min-width: 6.4rem;
      z-index: 1;
    }

    &__header-controls-wrapper {
      display: grid;
      grid-template-columns: minmax(2rem, auto) minmax(2rem, auto) 15rem minmax(2rem, auto) minmax(2rem, auto);
      justify-content: space-between;
      align-items: center;
      padding: 0 1.2rem;
    }

    &__header-control {
      margin: 0;
      padding: 0 1.2rem;
      border: none;
      font-family: inherit;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color_secondary_gray_dark;
      background-color: transparent;
      outline: none;
      cursor: pointer;

      &--locked,
      &:hover:not(&--disabled) {
        background-color: $color_background_dark;
      }
      &--disabled,
      &--locked {
        cursor: initial;
      }

      &--arrow {
        width: 2rem;
        height: 2rem;
        margin: 0 auto;
        padding: 0;
        border-radius: 100%;

        &-up {
          transform: rotate(90deg);
        }

        &-down {
          transform: rotate(90deg);
        }
      }
      &-image {
        width: 100%;
      }
    }

    &__decade,
    &__year {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 0.4rem;
      padding: 0.8rem 0.8rem 0.8rem;
    }

    &__month {
      display: grid;
      grid-row-gap: 0.4rem;
      max-width: 25.7rem;
      margin: 0 auto;
      padding:0 0.8rem 1.6rem;
    }

    &__week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-auto-rows: 3.2rem;
      grid-column-gap: 0.4rem;

      &--selected,
      &--with-hover:hover {
        position: relative;
        background-color: $color_background_dark;
        cursor: pointer;

        &::after,
        &::before {
          position: absolute;
          top: 0;
          left: 100%;
          content: '';
          width: 2.8rem;
          height: 100%;
          background-color: $color_background_dark;
        }

        &::before {
          left: unset;
          right: 100%;
          width: 7.2rem;
        }
      }

      &--selected {
        pointer-events: none;
        cursor: initial;
      }
    }

    &__week-title {
      align-self: center;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: $color_secondary_gray_light;
    }

    &__cell {
      min-height: 3.2rem;
      margin: 0 auto;
      padding: 0;
      border: 0;
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      background-color: transparent;
      outline: none;
      cursor: pointer;

      &--day {
        min-width: 3.2rem;
      }

      &--week {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 0.2rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color_primary;
      }

      &--year,
      &--month {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 5.6rem;
      }

      &--year {
        min-height: 4rem;
      }

      &--now {
        position: relative;
        color: $color_primary;
        &::after {
          position: absolute;
          left: 50%;
          bottom: 0;
          display: block;
          content: '';
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 0.2rem;
          background-color: $color_primary;
          transform: translate(-50%, -0.4rem);
        }
      }

      &--inactive {
        color: $color_secondary_gray_light;
      }

      &--rest-day {
        background-color: $color_background_dark;
      }
      &--selected,
      &:hover:not(&--without-hover):not(&--week) {
        color: $color_white;
        background-color: $color_primary;

        &::after {
          background-color: $color_white;
        }
      }
    }
    &__current-date-button {
      width: 100%;
      margin-top: 1.2rem;
      padding: 0;
      border: none;
      font-family: inherit;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color_primary;
      background-color: transparent;
      outline: none;
      cursor: pointer;

      &:active,
      &:hover {
        text-decoration: underline;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      flex-grow: 1;
      border-top: 1px solid #bbbdc0;
    }
  }

  &--locked {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-color: transparent;
    cursor: initial;
    pointer-events: none;

    &::after {
      position: absolute;
      left: 0;
      top: -0.3rem;
      display: block;
      width: 100%;
      height: 4.8rem;
      background-color: $color_secondary_gray_light;
      content: '';
      opacity: 0.15;
    }
  }

  &:hover {
    .calendar {
      &__toggle-button--with-clear {
        padding-right: 0.1rem;
      }

      &__clear-button--visible {
        min-width: 1rem;
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &__flex-wrapper {
    display: flex;
  }
}


