@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.controls {
  :global  {
    .controls__additional-controls {
      font-size: 1.6rem;
    }

    .select {
      width: max-content;
      font-size: 1.6rem;

      & {
        color: $color_primary;
        .filter-select {
          width: auto;
          height: auto;

          &__multi-value {
            &:first-child {
              padding-left: 0;
            }
          }

          &__multi-value__label {
            color: $color_primary;
          }

          &__placeholder {
            padding-left: 0;
            color: $color_primary;
          }

          &__control--is-focused {
            outline: none !important;
          }

          &__dropdown-indicator {
            path {
              fill: $color_primary;
            }
          }

          &__multi-value {
            background-color: transparent;
          }
        }

        .filter-select__control {
          cursor: pointer;
        }
      }
    }
  }
}

.pdfButton {
  @include button();
  @include primary-button();
  width: 11.1rem;
  height: 4rem;
  margin-top: 1.6rem;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 1;
  }
}
