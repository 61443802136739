@import 'assets/styles/variables.scss';

.searchContainer {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.clearButton {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0 0.6rem;
  cursor: pointer;
  color: $color_black;
  width: max-content;

  svg {
    width: 1.3rem;
    height: 1.3rem;
  }
}

.magnifierIcon {
  position: absolute;
  left: 0.8rem;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  color: $color_black;
}

.searchInput {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 2rem 0 2.5rem;
  font-family: "Etelka", Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: $color_secondary_text;
  background-color: transparent;
  outline: none;
  border: none;
}
