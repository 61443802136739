@import 'assets/styles/variables.scss';

.table {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  min-height: 100%;
  font-size: 1.6rem;
  font-weight: 400;
  color: $color_secondary_text;

  &__content-wrapper {
    padding-bottom: 10vh;

    &--with-infinite-scroll {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      & .table__rows-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    font-weight: 500;
    text-transform: capitalize;
    background-color: $color_background;
    z-index: 9;

    &--with-controls {
      top: 12.7rem;
    }
  }

  &__row {
    margin: 2px 0;
    border: 1px solid transparent;
    transition: background-color 0.15s;
    outline: none;

    &:focus-within,
    &:hover {
      background-color: white;
    }

    &--inactive {
      color: $color_secondary_gray_light;
    }

    &--was-warning {
      color: $color_primary;
    }
  }

  &__cell {
    box-sizing: border-box;
    min-width: 32px;
    font-size: 1.2rem;
    font-weight: inherit;
    text-align: left;
    text-transform: capitalize;

    &--heading {
      padding: 1.6rem 0;
      border-bottom: 1px solid $color_secondary_gray_light;
    }
  }

  &__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-height: 15rem;
    height: 100%;
    color: $color_secondary_gray_light;
    opacity: 0;
    animation-name: fade;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }

  &__no-data-title {
    margin: 1.4rem 0 0 1.6rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color_secondary_gray_light;
  }

  &__warning-title {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  &__warning-description {
    margin-top: 1.4rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  & .action-button {
    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
