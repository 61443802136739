@import 'assets/styles/variables.scss';

.update-reviews-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__title {
    margin-bottom: 0.8rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  &__description {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  &__content-modal {
    min-height: 30rem;
    max-height: calc(100vh - 35rem);
    padding-right: 1rem;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;

      &-track {
        background-color: $color_secondary_background_light;
        box-shadow: none;
      }

      &-thumb {
        min-height: auto;
        border-radius: 5px;
        background-color: darken($color_secondary_background_light, 10%);
      }
    }
  }

  &__action-title {
    margin-top: 1rem;
    padding: 0.4rem 0.8rem;
    font-size: 16px;
    line-height: 24px;
    background-color: $color_secondary_gray_light_0_15;
  }

  &__added-reviews {
    margin: 1.6rem 0.8rem 2.4rem;
    font-size: 14px;
    line-height: 18px;
    color: $color_primary;
  }

  &__controls {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
  }

  &__review-card {
    display: flex;
    padding: 0.8rem 0 1.6rem 0.8rem;
    &:not(:last-child) {
      border-bottom: 1px solid $color_secondary_gray_light;
    }
  }

  &__review-card-content {
    flex-grow: 1;
    margin: 0 1.6rem;
    padding-top: 0.3rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    max-height: max-content;
    overflow: hidden;
    color: $color_secondary_text;
  }

  &__review-card-text {
    & p {
    	margin: 0.5rem 0;
    }

    & strong {
      font-weight: 600;
    }

    & em {
      font-style: italic;
    }

    & li {
      margin: 0 1.3rem;
    }

    & ul,
    & ol {
      margin: 1rem 0;
    }

    & ul li {
      list-style-type: disc;
    }

    & ol li {
      list-style-type: decimal;
    }
  }

  &__review-card-title {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  &__review-card-about {
    margin-bottom: 0.5rem;
  }

  &__devstaff-data {
     &--pl {
      color: $color_primary;
    }
  }

  &__review-card-button {
    min-width: 14.4rem;
    padding: 0.7rem;
    border-color: $color_secondary_gray;
    font-size: 1.2rem;
    color: $color_secondary_text;
  }

  &__controls {
    justify-content: flex-start;
  }

  &__button {
    width: 291px;
    height: 40px;
    margin: 0 1.2rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    .spinner-container {
      padding: 0;
    }
  }
}
