@import 'assets/styles/variables.scss';

.search {
  position: relative;
  width: 100%;
  height: 100%;

  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    width: auto;
    height: 1.5rem;
    transform: translate(0.7rem, -50%);
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 2rem 0 2.5rem;
    border: none;
    font-family: inherit;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: $color_secondary_text;
    outline: none;
    background-color: inherit;

    &::-webkit-input-placeholder {
      color: $color_secondary_gray_light;
      line-height: 1.6rem;
    }

    &:active,
    &:focus {
      outline: 1px solid $color_primary;
      outline-offset: 0;
    }
  }

  &__button-clear {
    width: 1.0rem;
    height: 1.0rem;
    min-width: auto;
    min-height: auto;
    right: 0;
    top: 50%;
    transform: translate(-0.6rem, -60%);
  }
}
