@import "assets/styles/variables.scss";

.controls {
  :global {
    .controls__additional-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }

    .select {
      width: 90%;
      font-size: 1.6rem;

      & {
        color: $color_primary;

        .filter-select {
          width: auto;
          height: auto;

          &__multi-value__label {
            color: $color_primary;
          }

          &__placeholder {
            padding-left: 0;
            color: $color_primary;
          }

          &__control--is-focused {
            outline: none !important;
          }

          &__dropdown-indicator {
            path {
              fill: $color_primary;
            }
          }

          &__multi-value {
            background-color: transparent;
            padding-left: 0;
          }

          &__value-container {
            padding-left: 0;
            flex-wrap: wrap;
          }
        }

        .filter-select__control {
          cursor: pointer;
        }
      }
    }
  }
}

.groupControl {
  font-size: 14px;
}

.wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;

  :global {
    .expansion-summary {
      &__control {
        min-height: 4.8rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
        padding: 0 1.6rem;
        border-top: 1px solid #bbbdc0;
        background-color: rgba(187, 189, 192, 0.15);
      }

      &__label {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}
