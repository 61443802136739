@import "assets/styles/mixins.scss";
@import "assets/styles/variables.scss";

.attachmentView {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.8rem;
  background-color: $attachment_background_color;
  padding: 0.4rem 0.6rem 0.4rem 0.4rem;
  border-radius: 0.4rem;
}

.attachmentName {
  flex-grow: 1;
  font-family: Etelka, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: $color_secondary_text;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.active {
  text-decoration: none;
}

.removeFileIcon {
  cursor: pointer;
}

.attachmentLink {
  color: $color_secondary_text;

  &:hover {
    text-decoration: none;
  }
}

.viewMode {
  background-color: transparent;
}

.pdfIcon {
  width: 2.4rem;
  height: 2.4rem;
}

.error {
  background-color: $color_primary;
}
